
import {Observable} from 'rxjs/internal/Observable';
import {Observer} from 'rxjs/internal/types';
import {ResourceService} from './../../global/services/resource.service';
import { tap } from 'rxjs/operators';

import { from, of, Subscriber } from 'rxjs';
import { delay } from 'rxjs/internal/operators';
import { concatMap } from 'rxjs/internal/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';


export abstract class SearchArg {
  ignoreTypeChange: boolean = false;

  constructor( private onArgChangeCallback: (arg: SearchArg) => void,
               protected _name: string
  ) {
  }

  get name() { return this._name; }

  //
  // Ex:
  //  100
  //  Ford
  //  Fiat_Barchetta_Doblo,Chevrolet
  //
  abstract fromStr(str: string): void;

  abstract toStr(): string;

  /**
   * Value has been changed
   */
  abstract onChange(v: any): void;

  /**
   * Check if the argument is empty.
   */
  abstract isEmpty(): boolean;

  /**
   * Called by child-class when value has changed
   */
  protected onArgChange(): void {
    this.onArgChangeCallback(this);
  }

  /**
   * Called when the hmaType for the whole search has been changed
   */
  onTypeChange(type: string): void { }

  setData( d ) {

  }
}



// ---------------------------------------------------------------------------------------------------------------------
//
// Ex: a=100-200
//
export class SearchArgMinMax extends SearchArg {

  min: number | null;
  max: number | null;

  // "100-200", "-200", "100-"
  fromStr(str = ''): void {
    const t = str.split('-');

    if ( t.length === 2 ) {
      // "100",""
      // "","100"
      // "100","200"
      this.min = ~~t[0] ? ~~t[0] : null;
      this.max = ~~t[1] ? ~~t[1] : null;
    } else {
      this.min = this.max = null;
    }
  }

  toStr(): string {
    const n = this.min;
    const x = this.max;

    if ( !n && !x ) { return ''; }
    if ( n && x ) { return n + '-' + x; }
    if ( n ) { return n + '-'; }
    if ( x ) { return '-' + x; }
  }

  onChange(v: {min: number | undefined | null, max: number | undefined | null}): void {
    const changed = v.min !== this.min || v.max !== this.max;

    this.min = ~~v.min ? ~~v.min : null;
    this.max = ~~v.max ? ~~v.max : null;

    if ( changed || 1 ) {
      this.onArgChange();
    }
  }

  isEmpty() {
    return this.toStr() == '';
  }
}



// ---------------------------------------------------------------------------------------------------------------------
//
// Ex: a=100
//
export class SearchArgSingle extends SearchArg {
  val: string;

  fromStr(str: string): void { this.val = str; }
  onChange(v: string): void { this.val = v + ''; this.onArgChange(); }
  toStr() { return this.val; }

  onTypeChange(type: string) {
    if (this.ignoreTypeChange) {
      return;
    }

    if (this.name != 'type') {
      this.val = '';
      this.onArgChange();
    }
  }

  isEmpty() {
    return !this.val || this.val == '';
  }
}


// ---------------------------------------------------------------------------------------------------------------------
//
// Ex:  a=Ford
//      a=Ford_Escort
//      a=Ford_Escort_Kuga,Volvo_Amazon_V50
//
export interface ISearchArgMultiValNode {
  name: string;
  value: string;
  selected: boolean;
  children?: ISearchArgMultiValNode[];
  popular?: boolean;
}

export interface IChildVals {
  [name: string]: boolean;
}

export interface ISearchArgMultiValVal {
  [name: string]: IChildVals;
}




export class SearchArgMultiVal extends SearchArg {

  values: ISearchArgMultiValVal;
  nodes: ISearchArgMultiValNode[];
  pretty = '';
  hmaType: string;
  resSvc: ResourceService;
  nodeObserver: Observer<ISearchArgMultiValNode[]> | null = null;

  constructor(onArgChangeCallback: (arg: SearchArg) => void,
    protected _name: string,
    resSvc : ResourceService,
    hmaType: string,
  ) {
    super(onArgChangeCallback, _name);

    this.hmaType = hmaType;
    this.resSvc = resSvc;
  }

  fromStr(str = ''): void {
    const t = str.split(',');

    this.values = {};

    if( str.trim()) {
      t.forEach((value: string) => {
        //
        // Value er f.eks:  "Ford_Escort" "Ford"
        //
        const subvalues	= value.split('_');
        const valname		= subvalues[0];

        this.values[valname] = {};

        subvalues.shift();
        subvalues.forEach((subvalue) => {
          this.values[valname][subvalue] = true;
        });
      });
    }
    else {
    }

    this.resetNodes();
  }

  isEmpty() {
    return Object.keys(this.values).length == 0;
  }

  resetNodes() {
    if (!this.nodes) {
      return;
    }

    this.nodes.forEach((n) => {
      const gotParent = this.values[n.value] ? true : false;
       n.selected = gotParent;

       n.children.forEach((c) => {
         c.selected = gotParent && this.values[n.name][c.name] ? true : false;
       });
    });
  }

  setVal( n: string, c: string | null, onOff: boolean ) {

    if( c ) {
      if( onOff ) {
        this.values[n][c] = true;
      } else {
        delete this.values[n][c];
      }
    } else {
      if (onOff) {
        this.values[n] = {};
      } else {
        delete this.values[n];
      }
    }
    this.resetNodes();
    this.onArgChange();
  }

  //
  //
  //
  onChange(v: any): void {
  }

  /**
   * Callback for when the hmaType changes.
   *
   * @param type
   */
  onTypeChange(type: string) {
    if (type == this.hmaType) {
      return;
    }

    this.hmaType = type;
    const nodeCopy = this.nodes;
    this.nodes = null;

    this.getNodes().subscribe((newNodes) => {
      if (!newNodes || !nodeCopy || (newNodes.length !== nodeCopy.length)) {
        this.values = {};
      }

      if (this.nodeObserver) {
        this.nodeObserver.next(this.nodes);
      }
    });
  }

  /**
   * Get the selected values in string format, to be used in search.
   */
  toStr(): string {
    const all: string[] = [];

    for( const name of Object.keys( this.values )) {
      const children = Object.keys( this.values[name] ).join('_');
      const full = name + (children ? '_' + children : '');

      all.push(full);
    }

    return all.join(',');
  }

  /**
   * Get all search nodes used as options for this multi-val input.
   */
  getNodes(): Observable<ISearchArgMultiValNode[]> {
    const observable = new Observable<ISearchArgMultiValNode[]>(observer => {
      if (this.nodes) {
        observer.next(this.nodes);
        return;
      }

      this.resSvc.getMultiValNodes(this.name, this.hmaType).subscribe(
        (nodes) => {
          this.nodes = nodes;
          observer.next(this.nodes);
        }
      );
    });

    return observable;
  }

  setData(d) {
    this.nodes = d;
  }

  /**
   * Listen to changes of the search arg's nodes.
   */
  observeNodeChanges(): Observable<ISearchArgMultiValNode[]> {
    return Observable.create((observer) => {
      this.nodeObserver = observer;
      return () => { this.nodeObserver = null; };
    });
  }
}


// ---------------------------------------------------------------------------------------------------------------------
//
//
export class SearchArgs {

  private args: {[name: string]: SearchArg } = {};
  private observer: Observer<SearchArg> | null = null;
  private defaultValues = {
    type: 'hmaAuto'
  };

  constructor( private resourceSvc: ResourceService ) {
  }



  //
  //
  //
  private createArg(name: string, param?: string): SearchArg | SearchArgMinMax {

    const type = this.args['type'] ? this.args['type']['val'] : this.defaultValues['type'];

    let tmp: SearchArg;

    const cb = this._onArgChange.bind(this);
    const rs = this.resourceSvc;

    switch ( name ) {
      case  'source':
      case  'type':
      case  't':
      case  'sort':
      case  'page':
      case  'limit':
        tmp = new SearchArgSingle( cb, name );
        break;

      case  'forhandler':
        tmp = new SearchArgSingle( cb, name );
        tmp.ignoreTypeChange = true;
        break;

      case  'numseats':
      case  'trailerweight':
      case  'ccm':
      case  'numsleepers':
      case  'weight':
      case  'length':
      case  'width':
      case  'price':
      case  'leaseprice':
      case  'yearmodel':
      case  'usageTime':
      case  'km':
      case  'hk':
      case  'range':
        tmp = new SearchArgMinMax( cb, name );
        break;

      case  'b':
      case  'category':
      case  'boatType':
      case  'fueltype':
      case  'boatMaterial':
      case  'motorIncluded':
      case  'motorType':
      case  'wheeldrive':
      case  'transmission':
      case  'sellertype':
      case  'saletype':
      case  'chassis':
      case  'fylke':
      case  'colour':
      case  'condition':
      case  'equipment':
        tmp = new SearchArgMultiVal( cb, name, this.resourceSvc, type );
        break;

      default:
        throw new Error('invalid argname, unable to create' ); // Todo: Throw InvalidArgname-exception
    }

    this.resourceSvc.getMultiValNodes(name, type).subscribe(
      (data) => tmp.setData(data)
    );

    tmp.fromStr(param || this.defaultValues[name] || '' );

    return tmp;
  }

  //
  //
  //
  getArg( name: string, param?: string ): SearchArg {
    if (!this.args[name]) {
      this.args[name] = this.createArg(name, param);
    }

    if ( this.args[name]) {
      return this.args[name];
    }

    // Still here with no arg? Bug, todo: throw better exception
    throw new Error('SearchArgs.getArg - que?!');
  }

  reset(newArgs = {}): void {
    Object.keys(this.args).forEach((k) => {
      const defaultValue = this.defaultValues[k] ? this.defaultValues[k] : '';
      this.args[k].fromStr(newArgs[k] ? newArgs[k] : defaultValue);
    });
  }


  toParams() {
    const p = {};

    Object.keys(this.args).forEach((k) => {
      const arg = this.args[k];
      const t = arg.toStr();
      if (t) {
        p[k] = t;
      }
    });

    return p;
  }


  //
  // Called by arg, value has changed.
  //
  _onArgChange(arg: SearchArg): void {
    if (this.observer) {
      this.observer.next(arg);
    }

    // If hmaType changes, call onTypeChange for all arguments
    if (arg && arg.name == 'type') {
      Object.keys(this.args).forEach((k) => {
        this.args[k].onTypeChange(arg.toStr());
      });
    }
  }

  observeArgChanges(): Observable<SearchArg> {
    return Observable.create((observer) => {
      this.observer = observer;
      return () => { this.observer = null; };
    });
  }

  /**
   * The hmaType for all args has changed, notify each argument.
   */
  onTypeChange(type: string) {
    Object.keys(this.args).forEach((k) => {
      this.args[k].onTypeChange(type);
    });
  }
}
