
import { Component, OnInit, OnDestroy, Renderer2, ElementRef, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { IsearchEntry, IsearchResult, SearchService} from '../../services/search.service';
import { Search } from '../../classes/search';
import { CurrSearchService } from '../../services/curr-search.service';
import { SearchArg } from '../../classes/search-args';
import { Subscription } from 'rxjs/internal/Subscription';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TemplateDataService } from '../../../global/services/template-data.service';
//import { AdsService } from '../../../global/services/ads.service';
import { WindowSizeService } from '../../../global/services/window-size.service';

import { Observable } from 'rxjs/internal/Observable';
import { merge, filter, map, debounce, switchMap, skip, take } from 'rxjs/operators';
import { of, timer } from 'rxjs/index';
import { Angulartics2 } from 'angulartics2';
import { MetaTagsService } from 'src/app/global/services/metatags.service';
import { HmaType, HmaTypeService } from 'src/app/global/services/hma-type.service';


@Component({
  selector: 'app-search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.css']
})

//
// IsGlobal: listens to router/navigation
//
//
export class SearchViewComponent implements OnInit, OnDestroy {


  search: Search;
  args: {} = {};
  listeners: Subscription[] = [];
  res$: Observable<IsearchEntry[]>;
  hmaTypes: HmaType[];
  hmaForMeta: string;
  hmaForButton: string; 

  opened = false;

  constructor (
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private currSearchService: CurrSearchService,
    private templateDataService: TemplateDataService,
    //private ads: AdsService,
    private windowSize: WindowSizeService,
    private angulartics2: Angulartics2,
    private renderer: Renderer2,
    private el: ElementRef,
    private metatags: MetaTagsService,
    hmaTypeService: HmaTypeService
  ) {
    templateDataService.setShowFooter(false);
    this.hmaTypes = hmaTypeService.getSearchEnabledTypes();
  }

  get screenSize() {
    return this.windowSize.getScreenSize();
  }

  get currentHmaType() {
    return this.search.args.getArg('type').toStr();
  }


  get fixedParams() {
    if (this.args['forhandler'] && !this.args['forhandler'].isEmpty()) {
      return { forhandler: this.args['forhandler'].toStr() };
    }

    return null;
  }

  ngOnInit() {

    //this.onScroll(); // ad fix Commenting out temp-ad-wrapper
    this.activatedRoute.queryParams.subscribe(params => {
      this.updateMetaTags(params['type'], params['yearmodel']);
      this.updateActiveClass(params['type'], params['yearmodel']);
    });


    this.search = this.currSearchService.get();

    const currArgs = this.activatedRoute.snapshot.queryParams;

    this.args = this.search.getAllArgs(currArgs);

    if (currArgs.openSearch) {
      this.opened = true;
    }
    
    const listener = this.search.args.observeArgChanges().pipe(
      debounce(() => timer(700))
    ).subscribe(() => {
      const params = this.search.args.toParams();

      this.res$ = this.search.doSearch().pipe(
        map((res: IsearchResult) => {
          return res.data;
        })
      );

      // Update URL with pure JS to avoid refresh
      let queryString = [];

      for (let i in params) {
        queryString.push(`${i}=${params[i]}`);
      }

      const newUrl = `/search?${queryString.join('&')}`;
      window.history.replaceState({}, '', newUrl);
      this.angulartics2.pageTrack.next({ path: newUrl });

      // Refresh top banner and skyscraper
      /* Commenting out ads
      if (this.windowSize.checkScreenSize('desktop')) {
        this.ads.refreshSlots(['topbanner', 'skyscraper']);
      }
      */
    });

    this.listeners.push(listener);

    //
    // Subscribe to route-changes
    //
    this.res$ = this.setupNavSearchListener();
  }


@HostListener('window:scroll', ['$event'])
onScroll(): void {
  const skyscraper = document.querySelector('.temp-ad-wrapper.skyscraper.fragus') as HTMLElement;
  const banner = document.querySelector('.temp-ad-wrapper.mobile-tall') as HTMLElement;


  if (!banner) {
    console.warn('Mobile banner element not found.');
    return;
  }

  if (!skyscraper) {
    console.warn('Skyscraper element not found.');
    return;
  }

  const docElement = document.documentElement;
  const scrollHeight = docElement ? docElement.scrollHeight : 0;
  const stopPoint = scrollHeight - window.innerHeight - 300;

  if (window.scrollY === 0) {
    banner.style.position = 'sticky';
    banner.style.top = '112px';
    banner.style.bottom = 'auto';
    skyscraper.style.position = 'sticky';
    skyscraper.style.top = '112px';
    skyscraper.style.bottom = 'auto';
    return;
  }

  // Normal scroll behavior
  if (window.pageYOffset < stopPoint) {
    banner.style.position = 'sticky';
    banner.style.top = '112px';
    banner.style.bottom = 'auto';
    banner.style.width = 'calc(100% - 24px)';
    skyscraper.style.position = 'sticky';
    skyscraper.style.top = '112px';
    skyscraper.style.bottom = 'auto';
  } else {
    banner.style.position = 'absolute';
    banner.style.bottom = '850px';
    banner.style.width = '100%';
    banner.style.top = 'auto';
    banner.style.left = '-12px';
    banner.style.paddingRight = '12px';
    banner.style.width = 'calc(100% - 24px)';
    skyscraper.style.position = 'absolute';
    skyscraper.style.bottom = '1100px';
    skyscraper.style.width = '100%';
    skyscraper.style.top = 'auto';
  }
}
  
  

  updateMetaTags(type: string, yearmodel: string) {
    if (type === 'hmaAuto' && yearmodel === '1900-1994') {
      this.hmaForMeta = 'veteranbil';
    } else {
      switch(type) {
        case 'hmaMobileHome':
          this.hmaForMeta = 'bobil';
          break;
        case 'hmaAuto':
          this.hmaForMeta = 'bil';
          break;
        case 'hmaCamping':
          this.hmaForMeta = 'campingvogn';
          break;
        case 'hmaMC':
          this.hmaForMeta = 'motorsykkel';
          break;
        case 'hmaBoat':
          this.hmaForMeta = 'båt';
          break;
        default:
          this.hmaForMeta = 'bil, campingvogn, bobil, motorsykkel og båt';
      }
    }

    this.metatags.setTitle('Søk etter '+ this.hmaForMeta + ' til salgs');
    this.metatags.setTagValues({
      'og:description': 'AutoDB.no - Norges største gratis annonseportal for ' + this.hmaForMeta + ' - Søk igjennom våre annonser for ' + this.hmaForMeta,
      'description': 'AutoDB.no - Norges største gratis annonseportal for ' + this.hmaForMeta + ' - Søk igjennom våre annonser for ' + this.hmaForMeta,
      'og:image': 'https://autodb.no/assets/img/autodb-logo.png',
    });
    this.metatags.addMetaTags();
  }

  updateActiveClass(type: string, yearmodel: string) {
    const buttons = this.el.nativeElement.querySelectorAll('.row.buttons .button-wrapper');
    buttons.forEach(button => {
      this.renderer.removeClass(button, 'active');
    });

    const beforeElement = this.el.nativeElement.querySelector('.before');
    const afterElement = this.el.nativeElement.querySelector('.after');
    if (beforeElement && afterElement) {
      this.renderer.removeClass(beforeElement, 'sibling-active');
      this.renderer.removeClass(afterElement, 'sibling-active');
    }

    switch (type) {
      case 'hmaAuto':
        if (yearmodel === '1900-1994') {
          this.activateButtons('.row.buttons .button-wrapper.hmaVeteran');
        } else {
          this.activateButtons('.row.buttons .button-wrapper.hmaAuto');
          this.addBeforeActiveClass();
        }
        
        break;
      case 'hmaMobileHome':
        this.activateButtons('.row.buttons .button-wrapper.hmaMobileHome');
        break;
      case 'hmaCamping':
        this.activateButtons('.row.buttons .button-wrapper.hmaCamping');
        break;
      case 'hmaMC':
        this.activateButtons('.row.buttons .button-wrapper.hmaMC');
        break;
      case 'hmaBoat':
        this.activateButtons('.row.buttons .button-wrapper.hmaBoat');
        this.addAfterActiveClass();
        break;
      default:
        break;
    }

  }

  activateButtons(selector: string) {
    const buttonWrappers = this.el.nativeElement.querySelectorAll(selector);
    buttonWrappers.forEach(button => {
      this.renderer.addClass(button, 'active');
    });
    this.highlightSiblings();
  }
  
  addBeforeActiveClass() {
    const beforeElement = this.el.nativeElement.querySelector('.before');
    if (beforeElement) {
      this.renderer.addClass(beforeElement, 'sibling-active');
    }
  }

  addAfterActiveClass() {
    const beforeElement = this.el.nativeElement.querySelector('.after');
    if (beforeElement) {
      this.renderer.addClass(beforeElement, 'sibling-active');
    }
  }
  
  highlightSiblings() {
    const prevBeforeActive = this.el.nativeElement.querySelector('.row.buttons .button-wrapper.before-active');
    const prevAfterActive = this.el.nativeElement.querySelector('.row.buttons .button-wrapper.after-active');

    if (prevBeforeActive) {
      this.renderer.removeClass(prevBeforeActive, 'before-active');
    }
    if (prevAfterActive) {
      this.renderer.removeClass(prevAfterActive, 'after-active');
    }

    const activeElement = this.el.nativeElement.querySelector('.row.buttons .button-wrapper.active');
    if (activeElement) {
      const previousElement = activeElement.previousElementSibling;
      const nextElement = activeElement.nextElementSibling;

      if (previousElement && previousElement.classList.contains('button-wrapper')) {
        this.renderer.addClass(previousElement, 'before-active');
      }
      if (nextElement && nextElement.classList.contains('button-wrapper')) {
        this.renderer.addClass(nextElement, 'after-active');
      }
    }
  }


  setupNavSearchListener(): Observable<IsearchEntry[]> {
    let listener = this.activatedRoute.queryParams.pipe(skip(1)).subscribe(
      (params) => {
        this.search.args.reset(params);
        this.res$ = this.search.doSearch().pipe(
          map((res: IsearchResult) => {
            return res.data;
          })
        );
      }
    );

    this.listeners.push(listener);

    return this.router.events.pipe(
      merge(of('initial-search')),
      filter((evt) => {
        return evt === 'initial-search';
      }),
      take(1),
      switchMap(() => {
        this.search.args.reset(this.activatedRoute.snapshot.queryParamMap['params']);
        return this.search.doSearch();
      }),
      map((res: IsearchResult) => {
        return res.data;
      })
    );
  }

  ngOnDestroy() {

    this.templateDataService.setShowFooter(true);
    this.metatags.clear();
  }
}
