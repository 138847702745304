import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { UserService } from '../../../global/services/user.service';
import { NotificationService } from '../../../global/services/notification.service';

@Component({
  selector: 'app-register-details',
  templateUrl: './register-details.component.html',
  styleUrls: ['./register-details.component.scss']
})
export class RegisterDetailsComponent implements OnInit {
  accountForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.accountForm = this.formBuilder.group({
      name: [''],
      addr: [''],
      postCode: ['', Validators.pattern(/[0-9]{4}/)],
      city: new FormControl({ value: ' ', disabled: true }),
      phone: ['', [Validators.pattern('[0-9]{8}')]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.accountForm.controls;
  }

  onSubmit() {
    this.userService.updateUserData(this.accountForm.value).subscribe(
      (userData) => {
        this.notificationService.successMessage('Dine detaljer har blitt lagret.');
        this.router.navigate(['/bruker/min-side']);
      },

      (error) => {
        this.notificationService.errorMessage('Kunne ikke lagre brukerdata. Prøv igjen senere.');
      }
    );
  }

  onChangePostCode() {
    if (this.accountForm.controls.postCode.valid && this.accountForm.controls.postCode.value != '') {
      this.userService.getCityFromPostCode(this.accountForm.controls.postCode.value).subscribe(
        (result) => {
          this.accountForm.controls.city.setValue(result.result);
        }
      )
    }
  }
}
