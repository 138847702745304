import { Injectable } from '@angular/core';
import { SessionHistoryService } from './session-history.service';
import { UserService } from './user.service';
import { UAParser } from "ua-parser-js";

import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(
    private sessionHistory: SessionHistoryService,
    private userService: UserService,
    private http: HttpClient
  ) { }

  sendSupportRequest(name: string, message: string, email?: string): Observable<any> {
    const subject = new Subject<any>();

    this.userService.getCurrentUser().subscribe(user => {
      let uaParser = new UAParser;

      const params = {
        name: name,
        message: message,
        email: email,
        user: user ? user.email[0].email : null,
        data: {
          routerLog: this.sessionHistory.getRouterLog(),
          httpLog: this.sessionHistory.getHttpLog(),
          userAgent: {
            os: uaParser.getOS(),
            device: uaParser.getDevice(),
            browser: uaParser.getBrowser(),
            complete: uaParser.getUA(),
          }
        }
      };

      this.http.post(`${environment.apiUrl}/x/support`, params).subscribe(
        (result) => subject.next(result),
        (error) => subject.error(error)
      )
    });

    return subject.asObservable();
  }
}
