import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { SupportService } from '../../services/support.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-support-form',
  templateUrl: './support-form.component.html',
  styleUrls: ['./support-form.component.scss']
})
export class SupportFormComponent implements OnInit {

  supportForm: FormGroup;
  submitted = false;
  sent = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private supportService: SupportService,
    private notifications: NotificationService
  ) { }

  ngOnInit() {
    this.supportForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.email]],
      message: ['', [Validators.required]],
    });
  }

  get f() {
    return this.supportForm.controls;
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  submitForm() {
    // Stop here if form is invalid
    if (this.supportForm.invalid) {
      return;
    }

    this.submitted = true;

    this.supportService.sendSupportRequest(this.f.name.value, this.f.message.value, this.f.email.value).subscribe(
      result => {
        this.submitted = false;
        this.sent = true;
      },
      error => {
        this.submitted = false;
        this.notifications.errorMessage('Kunne ikke sende supporthenvendelse. Prøv eventuelt på support@broommarked.no', false);
      }
    );
    this.submitted = false;
  }
}
