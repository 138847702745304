import { Directive, ElementRef, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { WindowScrollService } from '../services/window-scroll.service';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';
import * as offset from 'document-offset';

@Directive({
  selector: '[sticky-element]'
})
export class StickyElementDirective {
  scrollSubscription:Subscription = null;
  fixed = false;
  topOffset: number;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private windowScroll:WindowScrollService,
    private element: ElementRef,
    private renderer:Renderer2
  ) {
    this.initStickyElement();
  }

  ngOnInit() {
    this.topOffset = offset(this.element.nativeElement).top;
    const elementHeight = this.element.nativeElement.clientHeight;
    this.element.nativeElement.parentElement.style.height = elementHeight + 'px';
  }

  public initStickyElement(){
    if (isPlatformBrowser(this.platformId)) {
      this.scrollSubscription = this.windowScroll.scroll$
        .subscribe(this.handleScroll.bind(this));
    }
  }

  private handleScroll(currentScroll){
    if (!this.fixed && currentScroll > this.topOffset) {
      this.fixed = true;
      this.renderer.addClass(this.element.nativeElement, 'fixed');
    }
    else if(this.fixed && currentScroll < this.topOffset){
      this.fixed = false;
      this.renderer.removeClass(this.element.nativeElement, 'fixed');
    }
  }
}
