import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../global/services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../../global/services/notification.service';
import { MatDialog} from '@angular/material/dialog';

declare let gtag: Function;

@Component({
  selector: 'app-prospect-published',
  templateUrl: './prospect-published.component.html',
  styleUrls: ['./prospect-published.component.scss']
})
export class ProspectPublishedComponent implements OnInit {
  adId: number;
  adUrl: string;

  constructor(
    private notificationService: NotificationService,
    private authService: AuthenticationService,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.adId = +this.route.snapshot.paramMap.get('id');
    this.adUrl = `https://www.autodb.no/view/${this.adId}`;
  }

  shareOnFacebook() {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.adUrl)}`;
    window.open(facebookShareUrl, '_blank');
    this.trackShare('Facebook');
  }


  trackShare(platform: string) {
    gtag('event', 'share-from-dashboard', {
      'event_category': 'Social',
      'event_label': platform,
      'transport_type': 'beacon'
    });
  }

  goToDashboard() {
    this.router.navigate(['/bruker/annonser']);
  }

}
