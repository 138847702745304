import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prospectTitle'
})
export class ProspectTitlePipe implements PipeTransform {
  transform(prospect: any): string {
    const data = prospect.typedata ? prospect.typedata : prospect;

    if (prospect.type == 'hmaOther' || !data.brand) {
      return data.title;
    }

    return `${data.yearmodel} ${data.brand} ${data.model}`;
  }
}
