import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IprospectData } from './view.service';

@Injectable({
  providedIn: 'root'
})
export class RelatedContentService {

  constructor(private http: HttpClient) { }

  getTests(prospect: IprospectData, limit = 2): Observable<any[]> {

    const brand = prospect.typedata.brand || 'Ukjent merke'; // Default if brand is missing
    const model = prospect.typedata.model || 'Ukjent modell'; // Default if model is missing
    const yearmodel = prospect.typedata.yearmodel ? prospect.typedata.yearmodel.toString() : 'Ukjent år'; // Default if year is missing
    
    if (brand === 'Ukjent merke' || model === 'Ukjent modell' || yearmodel === 'Ukjent år') {
      console.warn('getTests: Using default values for missing typedata fields.', {
        brand, model, yearmodel
      });
    }

    const params = {
      b: brand,
      m: model,
      y: yearmodel,
      n: limit.toString()
    };

    return <Observable<any[]>>this.http.get(`${environment.apiUrl}/a/resources/broomtests`, { params: params }).pipe(
      map((result: any[]) => {
        result.forEach(test => {
          test.image = `https://broom.tv2.no${test.image}`;
        });

      return result;
      })
    );
  }

  /**
   * Get model info pages.
   */
  getModelInfo(prospect: IprospectData, limit = 2): Observable<any> {
    const params = {
      b: prospect.typedata.brand,
      m: prospect.typedata.model,
    };

    return <Observable<any>>this.http.get(`${environment.apiUrl}/a/resources/broomtestarticles`, { params: params }).pipe(
      map((result: any[]) => {
        // Look for years that match the current car.
        const searchYear = prospect.typedata.yearmodel;
        let page = result.find(row => {
          const years = row.years.match(/\(([0-9]{4})\-([0-9]{4})\)/);
          return years && years[1] <= searchYear && years[2] >= searchYear;
        });

        if (!page && result.length > 0) {
          page = result[result.length - 1];
        }

        if (page) {
          page.image = `https://broom.tv2.no${page.image}`;
        }

        return page;
      })
    );
  }
}
