import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SessionHistoryService } from '../services/session-history.service';

@Injectable({
  providedIn: 'root'
})
export class SessionLogInterceptor implements HttpInterceptor {
  constructor(private sessionHistory: SessionHistoryService, private router: Router) {

  }

  /**
   * Stores history of the latest HTTP requests.
   *
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        response => {
          if (response.type != 0) {
            const logItem = {
              time: new Date,
              method: request.method,
              url: response['url'],
              fromUrl: this.router.url,
              request: {
                body: request.body,
              },
              response: {
                status: response['status'],
                statusText: response['statusText'],
                body: response['body'],
              }
            };

            this.logToHttpLog(logItem);
          }
        },
        error => {
          this.logToHttpLog(error);
        },
      )
    );
  }

  logToHttpLog(logItem: any) {
    this.sessionHistory.logToHttpLog(logItem);
  }
}
