import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';
import { HmaTypeService, HmaType } from 'src/app/global/services/hma-type.service';
import { WindowSizeService } from '../../../global/services/window-size.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss']
})
export class SearchFiltersComponent implements OnInit {

  @Input() args;
  hmaTypes: HmaType[];
  desktop: boolean;

  enabledFields: { [hmaType: string]: string[]; } = {
    hmaAuto: ['b', 'condition', 'saletype', 'fueltype', 'price', 'yearmodel', 'km', 'fylke', 'sellertype', 'chassis', 'colour', 'hk', 'numseats', 'trailerweight', 'wheeldrive', 'transmission', 'equipment', 'range'],
    hmaBoat: ['b', 'usageTime', 'motorBrand', 'category', 'saletype', 'fueltype', 'price', 'yearmodel', 'fylke', 'sellertype', 'colour', 'hk', 'numseats', 'numsleepers', 'boatMaterial', 'motorType', 'boatType'],
    hmaMC: ['b', 'category', 'saletype', 'fueltype', 'price', 'yearmodel', 'km', 'fylke', 'sellertype', 'colour', 'ccm'],
    hmaMobileHome: ['b', 'category', 'condition', 'saletype', 'fueltype', 'price', 'yearmodel', 'km', 'fylke', 'sellertype', 'hk', 'numseats', 'numsleepers', 'trailerweight', 'length', 'width', 'weight', 'wheeldrive', 'transmission'],
    hmaCamping: ['b', 'saletype', 'price', 'yearmodel', 'fylke', 'sellertype', 'numsleepers', 'length', 'width', 'weight'],
    // All of these need its own equipment
  }

  // Shortcut for getting the currently selected hmaType
  get hmaType() {
    return this.args['type'] ? this.args['type'].val : null;
  }

  get fixedParams() {
    if (this.args['forhandler'] && !this.args['forhandler'].isEmpty()) {
      return { forhandler: this.args['forhandler'].toStr() };
    }
    return null;
  }

  constructor(
    private hmaTypeService: HmaTypeService, 
    private winSize: WindowSizeService, 
    private router: Router, 
    private el: ElementRef, 
    private renderer: Renderer2
  ) {
    this.hmaTypes = hmaTypeService.getSearchEnabledTypes();
    this.desktop = this.winSize.getAgentDevice() === 'desktop';
  }

  ngOnInit() { }

  fieldIsEnabled(fieldName: string) {
    return this.enabledFields[this.hmaType].indexOf(fieldName) >= 0;
  }

  onSelectChange(event: any, selectElement: HTMLSelectElement) {
    const value = event.target.value;
    const [type, yearmodel] = value.split(',');

    this.updateActiveClass(type, yearmodel);
    const queryParams: any = { type };
    if (yearmodel) {
      queryParams.yearmodel = yearmodel;
    }
    this.router.navigate(['/search'], { queryParams }).then(() => {
      selectElement.value = '';
    });
  }


  updateActiveClass(type: string, yearmodel?: string) {
    const buttons = this.el.nativeElement.querySelectorAll('.row.buttons .button-wrapper a.button');
    buttons.forEach(button => {
      this.renderer.removeClass(button, 'active');
    });

    const beforeElement = this.el.nativeElement.querySelector('.before');
    if (beforeElement) {
      this.renderer.removeClass(beforeElement, 'sibling-active');
    }

    switch (type) {
      case 'hmaAuto':
        if (yearmodel === '1900-1994') {
          this.activateButtons('.row.buttons .button-wrapper.hmaVeteran');
        } else {
          this.activateButtons('.row.buttons .button-wrapper.hmaAuto');
          this.addSiblingActiveClass();
        }
        break;
      case 'hmaMobileHome':
        this.activateButtons('.row.buttons .button-wrapper.hmaMobileHome');
        break;
      case 'hmaCamping':
        this.activateButtons('.row.buttons .button-wrapper.hmaCamping');
        break;
      case 'hmaMC':
        this.activateButtons('.row.buttons .button-wrapper.hmaMC');
        break;
      case 'hmaBoat':
        this.activateButtons('.row.buttons .button-wrapper.hmaBoat');
        break;
      default:
        break;
    }
  }

  activateButtons(selector: string) {
    const buttonWrappers = this.el.nativeElement.querySelectorAll(selector);
    buttonWrappers.forEach(buttonWrapper => {
      const button = buttonWrapper.querySelector('a.button');
      this.renderer.addClass(button, 'active');
    });
  }

  addSiblingActiveClass() {
    const beforeElement = this.el.nativeElement.querySelector('.before');
    if (beforeElement) {
      this.renderer.addClass(beforeElement, 'sibling-active');
    }
  }
  
}
