import { Component, OnInit } from '@angular/core';
import { ReportAbuseService } from '../../services/report-abuse.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-report-abuse',
  templateUrl: './report-abuse.component.html',
  styleUrls: ['./report-abuse.component.scss']
})
export class ReportAbuseComponent implements OnInit {

  constructor(
    private reportService: ReportAbuseService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.reportService.sendScamReport(params.id).subscribe();
    });
  }
}
