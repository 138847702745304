import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

@Component({
  selector: 'app-input-checkbox-groups',
  templateUrl: './input-checkbox-groups.component.html',
  styleUrls: ['./input-checkbox-groups.component.scss'],
  providers: [
    {provide: MatFormFieldControl, useExisting: InputCheckboxGroupsComponent}]
})
export class InputCheckboxGroupsComponent implements OnInit, OnChanges {
  @Input() inputName: string;
  @Input() options: Array<any>;
  @Input() placeholder: string;

  optionGroups: any;

  get value(): Array<number> | null {
    return this.form.get(this.inputName).value;
  }

  set value(selected: Array<number> | null) {}

  @Input() form: FormGroup;

  constructor(
  ) {
  }

  onContainerClick(event: MouseEvent) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (changes.options) {
      this.setupOptions();
    }
  }

  /**
   * On a checkbox change, send change back to form.
   */
  onCheckboxChange(item) {
    let values = this.value;

    if (item.selected) {
      values.push(item.id);
    }
    else {
      values = values.filter(id => id != item.id);
    }

    this.form.get(this.inputName).setValue(values);
  }

  /**
   * Orders options into groups based on their set 'category'.
   */
  setupOptions() {
    if (!this.options) {
      return;
    }

    let category;
    const values = this.form.get(this.inputName).value;

    this.optionGroups = [];
    this.options.forEach(item => {
      item.selected = values.indexOf(item.id) >= 0;

      category = this.optionGroups.find(element => element.name == item.category);

      if (!category) {
        category = {
          name: item.category,
          children: []
        };
        this.optionGroups.push(category);
      }

      category.children.push(item);
    });

    this.optionGroups = this.optionGroups.sort(function (a, b) { return b.children.length - a.children.length; });
  }
}
