import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPrice'
})
export class FormatPricePipe implements PipeTransform {
  transform(value: number | string): string {
    if (value === NaN || value === undefined || value === null) {
      return '0,-';
    }

    const locale = 'nb-NO';

    let price = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0
    }).format(Number(value));

    price += ',-';

    return price;
  }
}
