import { Component, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FooterVisibilityService } from 'src/app/global/services/footer-visibility.service';

@Component({
  selector: 'app-browser-registration',
  templateUrl: './browser-registration.component.html',
  styleUrls: ['./browser-registration.component.scss']
})
export class BrowserRegistrationComponent implements OnInit {

  public dynamicLink: SafeHtml = ''; // For holding the generated link
  currentYear: number;

  constructor(private renderer: Renderer2, private sanitizer: DomSanitizer, private footerVisibilityService: FooterVisibilityService) { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    const uuid = this.getUrlParam('uuid', 'Empty'); // Get 'uuid' parameter from URL
    const valcode = this.getUrlParam('valcode', 'Empty'); // Get 'valcode' parameter from URL
    this.generateLink(uuid, valcode);  // Generate the link dynamically

    // Hide footer
    this.footerVisibilityService.setFooterVisibility(false);
  }

  ngOnDestroy() {
    // Restore footer visibility when destroyed
    this.footerVisibilityService.setFooterVisibility(true);
  }

  // Function to extract URL parameters
  getUrlVars(): any {
    const vars: any = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
      vars[key] = decodeURIComponent(value);  // decodeURIComponent to handle URL encoded characters
      return '';
    });
    return vars;
  }

  // Function to get a specific URL parameter
  getUrlParam(parameter: string, defaultValue: string): string {
    const urlParameter = this.getUrlVars()[parameter];
    return urlParameter !== undefined ? urlParameter : defaultValue;
  }

  // Function to generate the dynamic link
  generateLink(uuid: string, valcode: string) {
    const link = `<strong><em> 
      <a href="https://www.autodb.no/validerepost/${uuid}/${valcode}" 
         target="_blank" 
         class="mceButtonLink" 
         style="background-color:#198e98;border-radius:8px;border:1px solid #454545;color:#ffffff;
         display:block;font-family:'DM Sans', sans-serif;font-size:16px;font-weight:normal;font-style:normal;
         padding:16px 28px;text-decoration:none;min-width:30px;text-align:center;direction:ltr;letter-spacing:0px">
         Bekreft registrering
      </a>
    </em></strong>`;
    
    // Bypass Angular's sanitization for this dynamic HTML
    this.dynamicLink = this.sanitizer.bypassSecurityTrustHtml(link);
  }

}
