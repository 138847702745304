export const HmaTypes = [
  {
    type: 'hmaAuto',
    id: 'hmaAuto',
    name: 'Bil',
    editName: 'Bil',
    readable: 'bil',
    enabledSearch: true,
    enabledEdit: true,
    payToPublish: true,
    minPrice: 3000,
  },
  {
    type: 'hmaMC',
    id: 'hmaMC',
    name: 'MC',
    editName: 'MC, ATV, Moped, Mopedbil, Snøscooter',
    readable: 'motorsykkel/ATV',
    enabledSearch: true,
    enabledEdit: true,
    payToPublish: true,
    minPrice: 1500,
  },
  {
    type: 'hmaMobileHome',
    id: 'hmaMobileHome',
    name: 'Bobil',
    editName: 'Bobil',
    readable: 'bobil',
    enabledSearch: true,
    enabledEdit: true,
    payToPublish: true,
    minPrice: 3000,
  },
  {
    type: 'hmaCamping',
    id: 'hmaCamping',
    name: 'Camping',
    editName: 'Campingvogn',
    readable: 'campingvogn',
    enabledSearch: true,
    enabledEdit: true,
    payToPublish: true,
    minPrice: 1000,
  },
  {
    type: 'hmaBoat',
    id: 'hmaBoat',
    name: 'Båt',
    editName: 'Båt',
    readable: 'båt',
    enabledSearch: true,
    enabledEdit: true,
    payToPublish: true,
    minPrice: 1000,
  },
];
