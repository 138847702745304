import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterVisibilityService {
  private footerVisibility = new BehaviorSubject<boolean>(true);  // Defaults to footer visible
  footerVisibility$ = this.footerVisibility.asObservable();

  setFooterVisibility(isVisible: boolean) {
    this.footerVisibility.next(isVisible);
  }
}