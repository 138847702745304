import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ElementRef, ViewChild, HostListener } from '@angular/core';
import { BroomImagesService } from '../../../global/services/broom-images.service';
import { SwiperDirective } from 'ngx-swiper-wrapper';
import { PinchZoomComponent } from 'ngx-pinch-zoom';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent implements OnChanges, OnInit {

  @Input() imageData: Array<any> = [];
  @Input() imageIds: number[] = [];
  @Input() loadPrevNext = true;
  @Input() heightRatio: number;
  @Input() fixedHeight: number;
  @Input() noCrop = false;
  @Input() showNextPrev = true;
  @Input() enableKeyboard: boolean;
  @Input() videoUrl: string;
  @Input() load = true;
  @Input() link: string;
  @Input() startIndex = 0;
  @Input() disableSwipe = false;
  @Input() minImageSize = 400;
  @Input() enablePinchZoom = false;

  @Output() onImageClick: EventEmitter<any> = new EventEmitter();

  
  @ViewChild(SwiperDirective) swiper: SwiperDirective;
  @ViewChild(PinchZoomComponent) pinchZoom;

  slides: Array<any> = [];
  el: ElementRef;
  height: number;
  slideshowIsSetup = false;
  public index = 0;
  firstImageBackground: string;
  hasCaptions: boolean;
  inZoom = false;

  config: any;

  inited = false;

  get hasOnImageClick() {
    return this.onImageClick.observers.length > 0;
  }

  get isZoomed() {
    return this.enablePinchZoom && this.pinchZoom ? this.pinchZoom.scale > 1 : false;
  }

  constructor(el: ElementRef, private imageService: BroomImagesService) {
    this.el = el;
  }

  
  

  ngOnInit() {
    this.config = {
      loop: true,
      lazy: {
        loadPrevNext: this.loadPrevNext
      },
      autoHeight: false,
      shortSwipes: !this.disableSwipe,
      longSwipes: !this.disableSwipe,
      allowTouchMove: !this.disableSwipe
    };

    if (this.enablePinchZoom) {
      /*
      setInterval(() => {
        this.config.shortSwipes = !this.isZoomed;
        this.config.longSwipes = !this.isZoomed;
        this.config.allowTouchMove = !this.isZoomed;
      }, 500);

       */
    }
  }

  ngOnChanges(changes) {
    this.setupSlider();

    if (!this.inited) {
      this.inited = true;
    } else {
      if (this.swiper) {
        this.swiper.config.loop = false;
        this.swiper.setIndex(this.startIndex, 0, true);

        setTimeout(() => {
          if (this.swiper) {
            this.swiper.config.loop = true;
          }
        }, 0);
      }
    }
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft' && this.swiper && this.enableKeyboard) {
      this.swiper.prevSlide();
    }
    if (event.key === 'ArrowRight' && this.swiper && this.enableKeyboard) {
      this.swiper.nextSlide();
    }
  }

  public nextSlide() {
    this.swiper.nextSlide();
  }

  public prevSlide() {
    this.swiper.prevSlide();
  }

  public gotoSlide(index: number) {
    this.swiper.setIndex(index, 500, true );
  }

  imageClickedAction() {
    if (this.onImageClick) {
      this.onImageClick.emit(this.index);
    }
  }

  setupSlider() {
    if (!this.imageData) {
      return;
    }

    // Calculate slider size based on parent element and height ratio
    const nativeElement = this.el.nativeElement;
    const width = Math.max(this.minImageSize, Math.ceil(nativeElement.parentNode.clientWidth / 400) * 400);
    const imageHeight = this.noCrop ? 0 : Math.round(width * this.heightRatio);
    this.height = this.fixedHeight ? this.fixedHeight : Math.round(nativeElement.parentNode.clientWidth * this.heightRatio);

    // Set up the image URLs and captions for the image slider
    this.slides = [];

    for (let i = 0; i < this.imageData.length; i++) {
      if (this.imageData[i].id) {
        const slide = {
          url: this.imageService.getImageUrl(this.imageData[i].id, width, imageHeight),
          caption: this.imageData[i].name || this.imageData[i].desc || this.imageData[i].text
        };
        this.slides.push(slide);

        if (slide.caption && slide.caption != '') {
          this.hasCaptions = true;
        }
      }

      if (this.videoUrl && this.videoUrl != '' && i === 0) {
        // Add video embed, as second slide, if a video URL is present
        // Todo: add support for video-embed
        /*
        const videoEmbed = this.embedService.embed(
          this.videoUrl,
          {
            query: {
              controls: 0,
              modestbranding: 1,
            },
            attr: {
              width: nativeElement.parentNode.clientWidth,
              height: this.height
            }
          }
        );

        this.slides.push({
          embed: videoEmbed
        });
         */
      }
    }

    for (let i = 0; i < this.imageIds.length; i++) {
      if (this.imageIds[i]) {
        this.slides.push({
          url: this.imageService.getImageUrl(this.imageIds[i], width, imageHeight),
          caption: ''
        });
      }
    }

    if (this.slides.length > 0) {
      this.firstImageBackground = `url(${this.slides[0].url})`;
    }

    setTimeout(() => {
      this.slideshowIsSetup = true;
      this.swiper.setIndex(this.startIndex, 500, true);
    });
  }
}
