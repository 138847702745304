import { Directive, ElementRef, HostListener, HostBinding, Input, SimpleChanges, OnChanges } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BroomImagesService } from '../../services/broom-images.service';

@Directive({
  selector: '[appDynamicImage]'
})
export class DynamicImageDirective implements OnChanges {
  @Input() appDynamicImage: number;
  @Input() heightRatio: number;
  @Input() minSize: number = 400;
  element: ElementRef;

  protected _elementClass: string[] = [];

  @HostBinding('class')
  get elementClass(): string {
      return this._elementClass.join(' ');
  }

  constructor(el: ElementRef, private imageService: BroomImagesService) {
    this.element = el;
  }

  ngOnChanges(changes: SimpleChanges) {
    const nativeElement = this.element.nativeElement;

    if (!this.appDynamicImage) {
      nativeElement.style.paddingBottom = `${(this.heightRatio * 100)}%`;
      this._elementClass.push('placeholder-image');
      return;
    }

    // Sets width based on the display width, in steps of minSize, and height based on ratio
    const width = Math.ceil(nativeElement.parentNode.clientWidth / this.minSize) * this.minSize;
    const height = this.heightRatio ? Math.round(width * this.heightRatio) : 0;

    nativeElement.src = this.imageService.getImageUrl(this.appDynamicImage, width, height);
  }
}
