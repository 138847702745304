import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-expand-details-modal',
  templateUrl: './expand-details-modal.component.html',
  styleUrls: ['./expand-details-modal.component.scss']
})
export class ExpandDetailsModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ExpandDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  // Method to close the modal
  closeModal(): void {
    this.dialogRef.close();
  }
}