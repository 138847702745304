import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, subscribeOn, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SavedProspectsService {
  savedProspects: SavedProspect[];
  constructor(private http: HttpClient, private userService: UserService) { }

  saveProspect(prospectId, imageId, type) {
    return this.updateSavedProspect(prospectId, imageId, type, true);
  }

  deleteProspect(prospectId, imageId, type) {
    return this.updateSavedProspect(prospectId, imageId, type, false);
  }

  updateSavedProspect(prospectId, imageId, type, saved) {
    const data = {
      id: prospectId,
      imgid: imageId,
      t: type,
      save: saved
    };

    return this.http.put<SavedProspect>(`${environment.apiUrl}/e/user/setsavedad`, data).pipe(
      tap(prospect => {
        if (!saved) {
          this.savedProspects = this.savedProspects.filter(i => i.aditemid !== prospectId);
        } else {
          this.savedProspects.push(prospect);
        }
      })
    );
  }

  getSavedProspects(): Observable<SavedProspect[]> {
    return new Observable<SavedProspect[]>((observer) => {
      if (this.savedProspects) {
        observer.next(this.savedProspects);
        observer.complete();
        return;
      }

      this.userService.getCurrentUser().subscribe(
        (user) => {
          this.savedProspects = user.savedAds;
          observer.next(this.savedProspects);
          observer.complete();
        },
        (error) => {
          observer.complete();
        });
    });
  }
}

export class SavedProspect {
  aditemid: number;
  imgid:    number;
  savetime: Date;
  type:     string;
  title:    string;
}
