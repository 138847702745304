import { Component } from '@angular/core';
import { AuthenticationService } from '../../../global/services/authentication.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../../global/services/notification.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private notificationService: NotificationService
  ) { }

  logOut() {
    this.authService.logout();
    this.notificationService.successMessage('Du er nå logget ut.');
    this.router.navigate(['/']);
  }

}
