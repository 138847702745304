import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService, User } from '../../global/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(
    private http: HttpClient,
    private userService: UserService
  ) { }

  /**
   * Get available products for a given prospect.
   *
   * @param prospectId
   */
  getProducts(prospectId: number): Observable<Product[]> {
    return <Observable<Product[]>>this.http.get(`${environment.apiUrl}/e/payment/stripe/products?aditemid=${prospectId}`);
  }

  /**
   * Get information about one product.
   * @param id
   */
  getProduct(id: number, adid?: number ): Observable<Product> {
    let url = `${environment.apiUrl}/e/payment/stripe/products/${id}`;
    if (adid) {
      url += `?aditemid=${adid}`;
    }
    return <Observable<Product>>this.http.get(url);
  }

  /**
   * Complete a purchase of a product.
   *
   * @param prospectId The prospect the product is purchased for.
   * @param productId Product ID.
   * @param paymentToken Payment token from the Stripe SDK.
   */
  sendPayment(prospectId: number, productId: number, paymentToken: string): Observable<PaymentResult> {
    const params = {
      adid: prospectId,
      productId: productId,
      stripeToken: paymentToken
    };

    return <Observable<PaymentResult>>this.http.post(`${environment.apiUrl}/e/payment/stripe/order`, params);
  }

  /**
   * Get all receipts for the current user.
   */
  getReceipts(): Observable<Receipt[]> {
    return <Observable<Receipt[]>>this.http.get(`${environment.apiUrl}/e/receipts`).pipe(
      map((result: ReceiptResult) => {
        return result.transactions;
      })
    );
  }

  /**
   * Get a specific receipt.
   */
  getReceipt(id: string): Observable<Receipt> {
    return <Observable<Receipt>>this.getReceipts().pipe(
      map((result: Receipt[]) => {
        const receipt = result.find((val) => val.stripeId == id);

        this.userService.getCityFromPostCode(receipt.contact.postNo).subscribe(
          (result) => {
            receipt.contact.place = result.result;
          }
        );

        return receipt;
      })
    );
  }
}

export interface Product {
  id: number;
  price: number;
  name: string;
  description: string;
  expires?: Date;
}

export interface PaymentResult {
  code: string;
  msg: string;
  success: boolean;
}

export interface ReceiptResult {
  transactions: Receipt[];
}

export interface Receipt {
  aditemid: number;
  transid: number;
  amount: number;
  created: Date;
  prodDesc: string;
  prodName: string;
  stripeId: string;
  success: boolean;
  contact: { addr: string; name: string; place: string; postNo: string; };
}
