import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BroomImagesService {
  /**
   * Get an image URL for an automatically scaled image.
   *
   * @param imageId Image ID from the image database.
   * @param width
   * @param height
   * @returns string
   */
  getImageUrl(imageId: number, width: number = 0, height: number = 0) {
    return environment.imageServer + '/autoimg/' + imageId + '_' + width + 'x' + height + '.jpg';
  }
}
