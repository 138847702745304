
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IsearchEntry } from '../../services/search.service';
import { IOtherAd, OtheradsService } from '../../services/otherads.service';
import { WindowSizeService } from '../../../global/services/window-size.service';
import { Location } from '@angular/common';
import { ScrollPosService } from 'src/app/global/services/scroll-pos.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-result-entry',
  templateUrl: './result-entry.component.html',
  styleUrls: ['./result-entry.component.scss']
})
export class ResultEntryComponent implements OnInit {
  @Input() entry: IsearchEntry;
  @Input() prospects: Observable<IOtherAd[]>;
  

  @ViewChild('wrapper') wrapper: ViewChild;

  visibleInView = false;
  showRelated = true;
  openBadges = false;

  constructor (
    //private otherAds : OtheradsService,
    private route: ActivatedRoute,
    private router: Router,
    private windowSize: WindowSizeService,
    private scrollPosition: ScrollPosService
  ) {
    this.route.queryParams.subscribe(params => {
      this.showRelated = params.forhandler === undefined || params.forhandler === '';
    });
  }

  get entryTitle() {
    if (!this.entry) {
      return '';
    }

    if (this.entry.type == 'hmaOther') {
      return this.entry.title;
    }

    let title = `${this.entry.yearmodel} ${this.entry.brand}`;

    if (this.entry.model && this.entry.model.length <= 30) {
      title = `${title} ${this.entry.model}`;
    }

    return title;
  }

  ngOnInit() {
    /*
    if (!this.entry.isPrivate) {
      this.entry.related = this.otherAds.getAds(this.entry.type, this.entry.customerid).pipe(
        map((results) => {
          return results.filter(value => value.id !== this.entry.aditemid);
        })
      );
    }
    */
  }

  setVisible({ target, visible }: { target: Element; visible: boolean }) {
    this.visibleInView = visible;
  }

  toggleBadgesDesktop(open) {
    if (this.windowSize.checkScreenSize('mobile')) {
      return;
    }

    this.openBadges = open;
  }

  toggleBadgesMobile(event) {
    if (this.windowSize.checkScreenSize('mobile')) {
      event.stopPropagation();
      event.preventDefault();
      this.openBadges = !this.openBadges;
    }
  }

  onEntryClick(event) {
    this.scrollPosition.storeScroll('entry-' + this.entry.aditemid);
  }
}
