import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { UserService } from '../../../global/services/user.service';
import { NotificationService } from '../../../global/services/notification.service';

@Component({
  selector: 'app-validate-user',
  templateUrl: './validate-user.component.html',
  styleUrls: ['./validate-user.component.scss']
})
export class ValidateUserComponent implements OnInit {

  validation: object;
  error = false;
  resent = false;
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.uuid && params.code) {
        this.validation = {
          uuid: params.uuid,
          code: params.code,
        };

        this.loading = true;

        this.userService.validate(this.validation)
          .pipe(first())
          .subscribe(
            data => {
              this.router.navigate(['/bruker/registrer-ekstra']);
              this.notificationService.successMessage('Brukeren din er nå registert. Velkommen til AutoDB!');
            },
            error => {
              let errorMessage = 'Noe gikk galt ved valideringen av bruker. Prøv på nytt senere.';

              switch (error.status) {
                case 422:
                  errorMessage = 'Koden er feil. Prøv på nytt.';
                  break;
                case 404:
                  errorMessage = 'Finner ikke brukeren. Prøv å registrere på nytt.';
                  break;
                case 410:
                  errorMessage = 'Valideringen har blitt forsøkt for mange ganger. Vent noen minutter og prøv igjen.';
                  break;
              }

              this.notificationService.errorMessage(errorMessage);
              this.error = true;
            }
          );
      }
    });
  }

  resendValidation() {
    this.userService.resendValidation(localStorage.getItem('registerEmail')).subscribe(
      (result) => {
        this.resent = true;
      }, 
      (error) => {
        this.notificationService.errorMessage('Noe gikk galt med sendingen av ny valideringslenke. Kontakt administrator hvis problemet vedvarer.');
      }
    );
  }
}
