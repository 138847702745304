import { Component, OnInit } from '@angular/core';
import { SearchService, IsearchResult } from '../../services/search.service';
import { Search } from '../../classes/search';
import { Observable ,  Subscription, timer } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrSearchService } from '../../services/curr-search.service';
import { debounce } from 'rxjs/operators';
import { WindowSizeService } from '../../../global/services/window-size.service';
import {ResourceService} from '../../../global/services/resource.service';
import { TemplateDataService } from '../../../global/services/template-data.service';
import { HmaTypeService } from 'src/app/global/services/hma-type.service';
import {multivalData} from '../../../global/static/multi-val-data';
import { ArticleService, Article } from 'src/app/articles/services/article.service';
import { MetaTagsService } from 'src/app/global/services/metatags.service';

@Component({
  selector: 'app-frontpage',
  templateUrl: './frontpage.component.html',
  styleUrls: ['./frontpage.component.css']
})
export class FrontpageComponent implements OnInit {
  search: Search;
  args: {} = {};
  opened = false;
  argsChanged$: Subscription;

  recommended$: Observable<IsearchResult>;
  screenSize: string;

  articles: Article[] = [];

  values;
  currModels;
  hmaTypes;

  selectedType: string = 'hmaAuto';
  selectedBrand : string = '';
  selectedModel : string = '';

  // Add a property to store the current time
  currentTime: Date = new Date();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private currSearchService: CurrSearchService,
    private searchService: SearchService,
    private windowSize: WindowSizeService,
    private resSvc : ResourceService,
    private templateData: TemplateDataService,
    private hmaTypeService: HmaTypeService,
    private articleService: ArticleService,
    private metatags: MetaTagsService,
  )
  {
    /*
    resSvc.getMultiValNodes('b', this.selectedType).subscribe(
      values => this.values = values
    );
*/
    this.values = resSvc.getPopData('b', this.selectedType );
    this.templateData.setShowFooter(false);
    this.screenSize = this.windowSize.getScreenSize();

    // Update current time every second
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);

  }

  ngOnInit() {
    this.metatags.setTitle('Norges største gratis annonseportal for kjøretøy');
    this.metatags.setTagValues({
      'og:description': 'AutoDB.no - Norges største gratis annonseportal for kjøretøy - Finn eller selg din bil, campingvogn, bobil, motorsykkel eller båt',
      'description': 'AutoDB.no - Norges største gratis annonseportal for kjøretøy - Finn eller selg din bil, campingvogn, bobil, motorsykkel eller båt',
      'og:image': 'https://autodb.no/assets/img/autodb-logo.png',
    });
    this.metatags.addMetaTags();
    this.recommended$ = this.searchService.getFrontpageContent();
    this.articles = this.articleService.getArticles();
    this.search = this.currSearchService.get();
    this.args = this.search.getAllArgs({});
    this.hmaTypes = this.hmaTypeService.getSearchEnabledTypes();
 
    this.argsChanged$ = this.search.args.observeArgChanges().pipe(
      debounce(() => timer(1000))
    ).subscribe(() => {
      const queryParams = this.search.args.toParams();
      queryParams['openSearch'] = 'true';
      this.router.navigate(['/search'], {queryParams: queryParams}); });
      
  }

  checkSize(name: 'mobile' | 'desktop') {
    return this.windowSize.checkScreenSize(name);
  }

  setCurrModel()
  {
    //this.currModels = this.values[this.selectedBrand];
    this.currModels = multivalData['b'][this.selectedType][this.selectedBrand];
    //console.log(this.selectedType,this.selectedBrand, this.currModels);
  }


  searchClick()
  {
    const args = {
      type: this.selectedType
    };

    let b = '';

    if (this.selectedBrand !== '') {
      b = this.selectedBrand;

      if (this.selectedModel && this.selectedModel !== '')  {
        b += '_' + this.selectedModel;
      }

      args['b'] = b;
    }

    this.router.navigate(['/search'], {queryParams: args});
  }

  selectType(type: string) {
    this.selectedType = type;
    this.selectedModel = '';
    this.selectedBrand = '';
    this.values = this.resSvc.getPopData('b', type );

    /*this.resSvc.getMultiValNodesRawJson('b', type).subscribe(
      values => {
        this.values = values;

        if (!values || values.length == 0) {
          this.searchClick();
        }
      }
    );;
    */
  }

  ngOnDestroy() {
    this.templateData.setShowFooter(true);
  }

  isDay(): boolean {
    const hours = this.currentTime.getHours();
    return hours >= 6 && hours < 18;
  }

}
