import { Component, OnInit } from '@angular/core';
import { PaymentService, Receipt } from '../../services/payment.service';
import { NotificationService } from '../../../global/services/notification.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.scss']
})
export class ReceiptsComponent implements OnInit {

  receipts$: Observable<Receipt[]>;

  constructor(
    private paymentService: PaymentService,
    private router: Router,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.receipts$ = this.paymentService.getReceipts();
  }

}
