import { Injectable } from '@angular/core';
import { WindowRefService } from './window-ref.service';

function _window(): any {
   // return the global native browser window object
   return window;
}

@Injectable({
  providedIn: 'root'
})
export class WindowSizeService {
  screenSizes = {
    mobile: [0, 729],
    tablet: [730, 979],
    desktop: [1024, 9999],
  };

  agentDevice: string | undefined;

  constructor(private windowRef: WindowRefService) {}

  get screenSize() {
    return this.getScreenSize();
  }

  checkScreenSize(size: 'mobile' | 'tablet' | 'desktop') {
    const screenWidth = this.windowRef.nativeWindow.innerWidth;
    return screenWidth >= this.screenSizes[size][0] && screenWidth <= this.screenSizes[size][1];
  }

  getScreenSize() {
    return this.checkScreenSize('desktop') ? 'desktop' : this.checkScreenSize('tablet') ? 'tablet' : 'mobile';
  }

  //
  // Sjekker om det er sansynlig at dette er en mobil, ikke veldig treffsikker, men funker generelt.
  //
  getAgentDevice(): string {
    if ( !this.agentDevice ) {
      const userAgent = window.navigator.userAgent;

      console.log(userAgent);

      if ( /iPhone|Android|iPad/.test(userAgent)) {
        this.agentDevice = 'mobile';
      } else {
        this.agentDevice = 'desktop';
      }
    }
    return this.agentDevice;
  }
}
