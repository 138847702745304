import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BroomImagesService } from 'src/app/global/services/broom-images.service';

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {
  title: string = 'AutoDBs';
  tags: any = {};

  private defaults = {
    'og:url': '/',
    'og:type': 'website',
    'og:description': 'Bilannonser',
    'description': 'Bilannonser',
  };

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private imageService: BroomImagesService
  ) { }

  /**
   * Set the value for one meta tag.
   *
   * @param name
   * @param value
   */
  setTagValue(name: string, value: string) {
    this.tags[name] = value;
  }

  /**
   * Set any number of tags.
   *
   * @param values The tags, with tag name as keys.
   */
  setTagValues(values: any) {
    for (let o in values) {
      this.tags[o] = values[o];
    }
  }

  /**
   * Set the sharing image based on a Broom image ID.
   *
   * @param imageId ID of a Broom image.
   */
  setImageById(imageId: number) {
    this.setTagValue('og:image', this.imageService.getImageUrl(imageId, 1500));
  }

  /**
   * Set the page title.
   */
  setTitle(title: string) {
    this.title = `${title} | AutoDB`;
  }

  /**
   * Clear all current metatags and revert to defaults.
   */
  clear() {
    this.title = 'AutoDB';

    for (let o in this.tags) {
      this.metaService.removeTag(`name="${o}"`);
    }

    this.tags = {};
    this.addMetaTags();
  }

  /**
   * Add all current metatags to the HTML of the current page.
   */
  addMetaTags() {
    this.tags['og:url'] = window.location.href;
    let tags = {...this.defaults, ...this.tags };

    tags['og:title'] = this.title;

    for (let o in tags) {
      if (this.metaService.getTag(`name="${o}"`)) {
        this.metaService.removeTag(`name="${o}"`);
      }

      this.metaService.addTag({ name: o, content: tags[o] }, false);
    }

    this.titleService.setTitle(this.title);
  }

  
}
