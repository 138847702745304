import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
    constructor(private http: HttpClient, private userService: UserService) { }

    login(email: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/x/login`, { u: email, p: password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user['x-access-token']) {
                    user.token = user['x-access-token'];

                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('userAuth2', JSON.stringify(user));
                    this.userService.fetched = false;
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('userAuth2');
        this.userService.fetched = false;
        return this.http.post<any>(`${environment.apiUrl}/x/logout`, {});
    }

    isLoggedIn(): Observable<boolean> {
      const loggedIn = <Observable<boolean>>Observable.create((observer) => {
        if (!localStorage.getItem('userAuth2')) {
          observer.next(false);
          observer.complete();
        }
        else {
          this.userService.getCurrentUser().subscribe(
            (result) => { observer.next(true); observer.complete(); },
            (error) => { observer.next(false); observer.complete(); }
          )
        }
      });


      return loggedIn;
    }
}
