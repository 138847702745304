import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScrollPosService } from '../../services/scroll-pos.service';

@Component({
  selector: 'app-scroll-pos-restore',
  templateUrl: './scroll-pos-restore.component.html',
  styleUrls: ['./scroll-pos-restore.component.scss']
})
export class ScrollPosRestoreComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private scrollPosition: ScrollPosService) { }

  ngOnInit() {
    setTimeout(() => {
      this.scrollPosition.restoreScroll();
    }, 100);
  }
}
