import {Component, OnInit, Input, OnChanges} from '@angular/core';
import { SearchArgSingle } from '../../../classes/search-args';

@Component({
  selector: 'app-inp-single',
  templateUrl: './inp-single.component.html',
  styleUrls: ['./inp-single.component.css']
})
export class InpSingleComponent implements OnInit {

  @Input() arg: SearchArgSingle;
  @Input() label: string;
  @Input() title: string;
  @Input() inlineLabel = false;

  constructor() { }

  ngOnInit() {
  }

  onChange() {
    this.arg.onChange(this.arg.val);
  }

}
