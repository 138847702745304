import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, subscribeOn, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SavedSearchesService {

  savedSearches: SavedSearch[];

  constructor(private http: HttpClient, private userService: UserService) { }

  saveSearch(search: SavedSearch): Observable<SavedSearch> {
    const data = {
      a: search.status === 'active',
      n: search.name,
      q: search.query,
      t: search.amsType,
      i: search.interval
    };

    return this.http.post<SavedSearch>(`${environment.apiUrl}/e/user/savesearch`, data).pipe(
      tap((savedSearch) => {
        if (!this.savedSearches) {
          this.savedSearches = [];
        }

        this.savedSearches.push(savedSearch);
      })
    );
  }

  updateSearch(search: SavedSearch): Observable<{}> {
    const params = {
      n: search.name,
      s: search.status,
      t: search.amsType,
      i: search.interval,
      q: search.query
    };

    return this.http.put(`${environment.apiUrl}/e/user/modsearch`, params);
  }

  deleteSearch(search: SavedSearch): Observable<{}> {
    const params = {
      n: search.name,
      s: 'deleted',
      t: search.amsType
    };

    return this.http.put(`${environment.apiUrl}/e/user/modsearch`, params).pipe(
      tap(result => {
        const index: number = this.savedSearches.indexOf(search, 0);

        if (index > -1) {
          this.savedSearches.splice(index, 1);
        }
      })
    );
  }

  updateStatus(search: SavedSearch, newStatus): Observable<{}> {
    const params = {
      n: search.name,
      s: newStatus,
      t: search.amsType
    };

    return this.http.put(`${environment.apiUrl}/e/user/modsearch`, params).pipe(
      tap(result => {
        search.status = newStatus;
      })
    );
  }

  getSavedSearches(): Observable<SavedSearch[]> {
    const result = new Observable<SavedSearch[]>((observer) => {
      if (this.savedSearches) {
        observer.next(this.savedSearches);
        observer.complete();
        return;
      }

      this.userService.getCurrentUser().subscribe((user) => {
        this.savedSearches = [];

        for (const search of user.savedSearch) {
          this.savedSearches.push(search);
        }

        observer.next(this.savedSearches);
        observer.complete();
      });
    });

    return result;
  }
}

export class SavedSearch {
  amsType:    string;
  created:    Date;
  interval:   string;
  name:       string;
  newAdsOnly: boolean;
  query:      any;
  status:     string;
}
