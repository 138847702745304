import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, ObservableInput } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../../global/services/notification.service';

export class MessageDetails {
  message: string;
  email: string;
  phone: string;
  adId: number;
  name: string;
}

export interface MessageStatus {
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private host: string;

  constructor( private http: HttpClient, private notifications: NotificationService ) {
    this.host = environment.apiUrl;
  }

  sendMessage(message: MessageDetails, recaptcha: string): Observable<Object> {
    const url = this.host + '/e/contact/send';
    const params = {
      e: message.email,
      i: message.adId,
      m: message.message,
      n: message.name,
      p: message.phone,
      recaptcha: recaptcha
    };

    return this.http.post(url, params).pipe(
      catchError(err => this.handleError(err))
    );
  }

  handleError(error: HttpErrorResponse) {
    const message = 'Kunne ikke sende melding. Sjekk at alle detaljer er korrekte og prøv igjen.';
    this.notifications.errorMessage(message);

    return throwError(message);
  }
}
