import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../global/services/authentication.service';
import { FooterVisibilityService } from '../../../global/services/footer-visibility.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  isVisible: boolean = true;  // Default to visible 
  isLoggedIn: boolean = false;

  constructor(private authService: AuthenticationService, private footerVisibilityService: FooterVisibilityService) { }

  ngOnInit() {
    this.authService.isLoggedIn().subscribe(
      (loggedIn) => {
        this.isLoggedIn = loggedIn;
      },
      (error) => {
        console.error('Error checking login status:', error);
      }
    );
    // Subscribe to footer visibility state
    this.footerVisibilityService.footerVisibility$.subscribe(
      (visible) => {
        this.isVisible = visible;
      }
    );
  }

}
