import { Injectable } from '@angular/core';
import { HmaTypes } from '../static/hma-types';

@Injectable({
  providedIn: 'root'
})
export class HmaTypeService {
  constructor() { }

  /**
   * Get all configured types.
   */
  getAllTypes(): HmaType[] {
    return HmaTypes;
  }

  /**
   * Get all types enabled for search.
   */
  getSearchEnabledTypes() {
    return this.getAllTypes().filter(el => el.enabledSearch);
  }

  /**
   * Get all types enabled for editing.
   */
  getEditEnabledTypes() {
    return this.getAllTypes().filter(el => el.enabledEdit);
  }

  /**
   * Get the readable name for a type.
   *
   * @param type
   */
  getName(type: string) {
    return HmaTypes.find(el => el.id == type).name;
  }

  /**
   * Get the config for a type.
   */
  getType(type: string) {
    return HmaTypes.find(el => el.id == type);
  }
}

export interface HmaType {
  type: string;
  id: string;
  name: string;
  editName: string;
  readable: string;
  enabledSearch: boolean;
  enabledEdit: boolean;
}
