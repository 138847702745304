import { Component, OnInit } from '@angular/core';
import {Routes} from '@angular/router';
import {ValidateUserComponent} from '../user/comp/validate-user/validate-user.component';

@Component({
  selector: 'app-privacy-terms',
  templateUrl: './privacy-terms.component.html',
  styleUrls: ['./privacy-terms.component.scss']
})
export class PrivacyTermsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

const routes: Routes = [
  { path: 'privacy-terms',  component: PrivacyTermsComponent },
];
