import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  providers: [
    {provide: MatFormFieldControl, useExisting: InputSelectComponent}]
})
export class InputSelectComponent implements OnInit, OnChanges {
  @Input() options: { name: string; value: string | number }[];
  @Input() inputName: string = '';
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() form: FormGroup;

  showSelect = false;

  get value(): string | null {
    return this.form.get(this.inputName).value;
  }

  set value(select: string | null) {}


  constructor() {
   }

  ngOnInit() {
  }

  ngOnChanges(changes) {
  }
}

