import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../global/services/authentication.service';
import { ProspectService, UserProspects } from '../../services/prospect.service';
import { ProspectEditService, ProspectSession } from '../../services/prospect-edit.service';
import { NotificationService } from '../../../global/services/notification.service';
import { Observable } from 'rxjs';
import { DialogService } from '../../../global/services/dialog.service';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentService } from '../../services/payment.service';

@Component({
  selector: 'app-my-prospects',
  templateUrl: './my-prospects.component.html',
  styleUrls: ['./my-prospects.component.scss']
})
export class MyProspectsComponent implements OnInit {
  prospects$: Observable<UserProspects>;
  sessions$: Observable<ProspectSession>;

  constructor(
    private prospectsService: ProspectService,
    private prospectEdit: ProspectEditService,
    private paymentService: PaymentService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.fetchProspects();
  }

  fetchProspects() {
    this.prospects$ = this.prospectsService.getProspects();
    this.sessions$ = this.prospectEdit.getProspectSessions();
  }

  /**
   * Activate a prospect.
   *
   * Checks if the prospect already has active products. If it does, activate it immediately.
   * Otherwise, redirect to the purchase page.
   *
   * @param prospect
   */
  activateProspect(prospect) {
    this.paymentService.getProducts(prospect.aditemid).subscribe(
      (products) => {
        if (products.filter((product) => product.expires).length > 0) {
          const options = { title: 'Ønsker du å aktivere denne annonsen igjen?' };

          this.dialogService.questionDialog(options).afterClosed().subscribe(result => {
            if (result) {
              this.prospectsService.activateProspect(prospect).subscribe(
                () => {
                  this.fetchProspects();
                  this.notificationService.successMessage('Annonsen har blitt markert som solgt.');
                },
                (error) => {
                  this.notificationService.errorMessage('Kunne ikke oppdatere annonse. Prøv på nytt senere.');
                }
              );
            }
          });
        }
        else {
          this.router.navigate(['/bruker/annonser/rediger/', prospect.aditemid, 'sms-valider']);
        }
      }
    )
  }

  deactivateProspect(prospect) {
    const options = {
      title: 'Er du sikker på at du vil deaktivere denne annonsen?',
    };

    this.dialogService.questionDialog(options).afterClosed().subscribe(result => {
      if (result) {
        this.prospectsService.deactivateProspect(prospect).subscribe(
          () => {
            this.fetchProspects();
            this.notificationService.successMessage('Annonsen har blitt deaktivert.');
          },
          (error) => {
            this.notificationService.errorMessage('Kunne ikke deaktivere annonse. Prøv på nytt senere.');
          }
        );
      }
    });
  }

  markSold(prospect) {
    const options = {
      title: 'Markere denne annonsen som solgt?',
    };

    this.dialogService.questionDialog(options).afterClosed().subscribe(result => {
      if (result) {
        this.prospectsService.markProspectSold(prospect).subscribe(
          () => {
            this.fetchProspects();
            this.notificationService.successMessage('Annonsen har blitt markert som solgt.');
          },
          (error) => {
            this.notificationService.errorMessage('Kunne ikke oppdatere annonse. Prøv på nytt senere.');
          }
        );
      }
    });
  }

  deleteProspect(prospect) {
    const options = {
      title: 'Er du sikker på at du vil slette denne annonsen for godt?',
    };

    this.dialogService.questionDialog(options).afterClosed().subscribe(result => {
      if (result) {
        this.prospectsService.deleteProspect(prospect).subscribe(
          () => {
            this.fetchProspects();
            this.notificationService.successMessage('Annonsen har blitt slettet.');
          },
          (error) => {
            this.notificationService.errorMessage('Kunne ikke slette annonse. Prøv på nytt senere.');
          }
        );
      }
    });
  }

  deleteSession(session) {
    const options = {
      title: 'Slette uferdig annonse?',
    };

    this.dialogService.questionDialog(options).afterClosed().subscribe(result => {
      if (result) {
        this.prospectEdit.deleteEditSession(session.esid).subscribe(
          () => {
            this.fetchProspects();
            this.notificationService.successMessage('Annonsen har blitt slettet.');
          },
          (error) => {
            this.notificationService.errorMessage('Kunne ikke slette annonse. Prøv på nytt senere.');
          }
        );
      }
    });
  }

  editProspect(prospect) {
    this.prospectEdit.getEditSession(prospect.aditemid).subscribe(
      (session) => { this.router.navigate(['/bruker', 'annonser', 'rediger', session.esid, 'edit']); }
    );
  }
}
