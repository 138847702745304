
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { merge, filter, map, flatMap } from 'rxjs/operators';
import { of, ReplaySubject } from 'rxjs/index';
import { Search } from '../classes/search';
import { IRelatedProspectData } from './view.service';
import { IOtherAd } from './otherads.service';

@Injectable({
  providedIn: 'root'
})

export class SearchService {
  readonly host = environment.apiUrl;

  private searchCache: {
    [cacheKey: string]: {
      data: ReplaySubject<IsearchResult>,
      observable: Observable<IsearchResult>
    },
  } = {};

  constructor( private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute ) {}

  doSearch(args = {}): Observable<IsearchResult> {
    const cacheKey = JSON.stringify(args);

    if (!this.searchCache[cacheKey]) {
      const replaySubject = new ReplaySubject<IsearchResult>(1);

      this.searchCache[cacheKey] = {
        data: replaySubject,
        observable: replaySubject.asObservable()
      };

      this.http.get( this.host + '/s/search/', { params: args }).subscribe(
        (result: IsearchResult) => {
          if (!args['type'] || args['type'] == 'hmaAuto') {
            this.addBadges(result);
          }

          replaySubject.next(result);
        }
      );
    }

    return this.searchCache[cacheKey].observable;
  }

  addBadges(res: any) {
    for (let i = 0; i < res.data.length; i++) {
      res.data[i].badges = this.getBadges(res.data[i]);
    }
  }

  getBadges(ad) {
    const sixMonthsAgo = Date.now() - (60 * 60 * 24 * 30 * 6 * 1000),
          nafDate = new Date(ad.nafControlDate),
          nafControlRecent = ad.nafControlDate !== 0 && nafDate.getTime() > sixMonthsAgo;

    let badges = [];

    if (ad.isPrivate) {
      if (ad.hasWarranty) { badges.push('Garanti'); }
      if (ad.hasQualityReport || nafControlRecent) { badges.push('Test/Tilstandsrapport/Egenmelding'); }
      if (ad.serviced) { badges.push('Dokumentert historikk'); }
    }
    else {
      badges.push('Forhandler');

      if (ad.hasWarranty) { badges.push('Garanti'); }
      if (ad.hasQualityReport || nafControlRecent) { badges.push('Test/Tilstandsrapport'); }
      if (ad.serviced || ad.hasHistory) { badges.push('Dokumentert historikk'); }
    }

    if (ad.euControlRecent) { badges.push('Nylig EU-kontroll'); }

    return badges;
  }

  getFrontpageContent(): Observable<IsearchResult> {
    return <Observable<IsearchResult>>this.http.get(this.host + '/s/frontpage');
  }
}


export interface IsearchResult {
  count: number;
  data: IsearchEntry[];
  limit: number;
  page: 0;
}


export interface IsearchEntry {
  aditemid:				number;
  type:						string; 		// internal type: hmaAuto = bil
  title:          string;
  price:					number;
  brand:					string;
  boatType:       string; 
  boatMaterial:   string; 
  motorIncluded:  boolean; 
  motorType:      string;
  model:					string;
  variant:				string;			// can be anything. should be cars specific model.
  yearmodel:			number;
  km:							number;
  ccounty:				string;			// customer county
  isActive:				boolean;
  isPrivate:			boolean;
  mainImageId:		number;
  sellerName:			string;
  sellerType:			number;			// 1 = private, 2 = dealer?
  timeModified:		string;
  timePublished:	string;
  sold:           boolean;
  pri?:						boolean;		// prioritized: payed top-position
  badges:         string[];
  related:        Observable<IOtherAd[]>;
  customerid?:    number;
  userUuid?:      string;
  hasHistory:     boolean;
  hasWarranty:    boolean;
  euControlDate:  Date;
  nafControlDate: Date;
  qualityReport:  string;
  imageIds:       number[];
  status:         string;
  saleType?:       string;
  leaseprice?:     number;
  source?:         number;
}
