import { Component, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FooterVisibilityService } from 'src/app/global/services/footer-visibility.service';

@Component({
  selector: 'app-browser-message',
  templateUrl: './browser-message.component.html',
  styleUrls: ['./browser-message.component.scss']
})
export class BrowserMessageComponent implements OnInit {

  public dynamicAdLink: SafeHtml = ''; // For holding the generated link
  public dynamicReplyLink: SafeHtml = ''; // For holding the generated link
  public navn: string = ''; // Holds the value for "navn"
  public mel: string = '';  // Holds the value for "mel"
  public tel: string = '';  // Holds the value for "tel"
  public epost: string = ''; // Holds the value for "epost"
  public tit: string = ''; // Holds the value for "tit"
  currentYear: number;

  constructor(private renderer: Renderer2, private sanitizer: DomSanitizer, private footerVisibilityService: FooterVisibilityService) { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    const wbid = this.getUrlParam('wbid', 'Empty'); // Get 'wbid' parameter from URL
    this.navn = this.getUrlParam('navn', 'Unknown'); // Get 'navn'
    this.mel = this.getUrlParam('mel', 'No message'); // Get 'mel'
    this.tel = this.getUrlParam('tel', 'No phone number'); // Get 'tel'
    this.epost = this.getUrlParam('epost', 'No email'); // Get 'epost'
    this.tit = this.getUrlParam('tit', 'No title'); // Get 'tit'
    
    // Hide footer
    this.footerVisibilityService.setFooterVisibility(false);

    this.generateAdLink(wbid);
    this.generateReplyLink(this.epost);
  }
  
  ngOnDestroy() {
    // Restore footer visibility when destroyed
    this.footerVisibilityService.setFooterVisibility(true);
  }

  // Function to extract URL parameters
  getUrlVars(): any {
    const vars: any = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
      vars[key] = decodeURIComponent(value);  // decodeURIComponent to handle URL encoded characters
      return '';
    });
    return vars;
  }

  // Function to get a specific URL parameter
  getUrlParam(parameter: string, defaultValue: string): string {
    const urlParameter = this.getUrlVars()[parameter];
    return urlParameter !== undefined ? urlParameter : defaultValue;
  }

  // Function to generate the dynamic link
  generateAdLink(wbid: string) {
    const adLink = ` 
      <a href="https://www.autodb.no/view/${wbid}" 
         target="_blank" 
         class="mceButtonLink" 
         style="background-color:#198e98;border-radius:8px;border:1px solid #454545;color:#ffffff;
         display:block;font-family:'DM Sans', sans-serif;font-size:16px;font-weight:normal;font-style:normal;
         padding:16px 28px;text-decoration:none;min-width:30px;text-align:center;direction:ltr;letter-spacing:0px;font-weight:700;">
         Se annonsen din
      </a>`;
    
    // Bypass Angular's sanitization for this dynamic HTML
    this.dynamicAdLink = this.sanitizer.bypassSecurityTrustHtml(adLink);
  }

  generateReplyLink(epost: string) {
    const replyLink = ` 
      <a href="mailto:${epost}"
       target='_blank' 
       class='mceButtonLink' 
       style=' font-weight:700;padding:15px 20px;vertical-align: middle;text-align:center;text-decoration:none;max-width:170px;background-color:#303030;
       border-radius:8px;border:1px solid #454545;color:#ffffff;display:block;font-family:\'DM Sans\', sans-serif;font-size:16px;font-weight:normal;
       font-style:normal;text-decoration:none;min-width:30px;'>Send Svar</a>`;
    
    // Bypass Angular's sanitization for this dynamic HTML
    this.dynamicReplyLink = this.sanitizer.bypassSecurityTrustHtml(replyLink);
  }
 
}
