import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../comp/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(public dialog: MatDialog) { }

  questionDialog(options: any | null) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    if (options !== null) {
      for (let i of Object.keys(options)) {
        dialogRef.componentInstance[i] = options[i];
      }
    }

    return dialogRef;
  }
}
