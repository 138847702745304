import { Component, AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { PaymentService, Product } from '../../services/payment.service';

declare var Stripe: any;

@Component({
  selector: 'app-prospect-payment',
  templateUrl: './prospect-payment.component.html',
  styleUrls: ['./prospect-payment.component.scss']
})
export class ProspectPaymentComponent implements OnInit {
  @ViewChild('cardNumberInput') cardNumberInput: ElementRef;
  @ViewChild('cardExpiryInput') cardExpiryInput: ElementRef;
  @ViewChild('cardCvcInput') cardCvcInput: ElementRef;
  cardNumber: any;
  cardExpiry: any;
  cardCvc: any;
  cardNumberHandler = this.onChange.bind(this);
  cardExpiryHandler = this.onChange.bind(this);
  cardCvcHandler = this.onChange.bind(this);

  product: Product;
  adId: number;

  error: string;
  loading = false;
  success = false;

  stripe: any;
  elements: any;

  client_secret: string;

  constructor(
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService
  ) { }

  ngOnInit() {

    this.client_secret = "9872489372473";

    this.stripe = Stripe(environment.stripePubKey);
    this.elements = this.stripe.elements({ locale: 'no' });

    this.activatedRoute.params.subscribe(
      (params) => {

        this.adId = parseInt(params.id);

        this.paymentService.getProduct(params.productId, this.adId).subscribe(
          (product: Product) => {
            this.product = product;
          }
        );
      }
    );
  }

  ngAfterViewInit() {
    const settings = {
      style: {
        base: {
          color: '#000',
          fontFamily: '"Oxygen", "Roboto", "Helvetica Neue"',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      },
      classes: {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid',
      },
    };

    this.cardNumber = this.elements.create('cardNumber', settings);
    this.cardNumber.mount(this.cardNumberInput.nativeElement);
    this.cardNumber.addEventListener('change', this.cardNumberHandler);

    this.cardExpiry = this.elements.create('cardExpiry', settings);
    this.cardExpiry.mount(this.cardExpiryInput.nativeElement);
    this.cardExpiry.addEventListener('change', this.cardExpiryHandler);

    this.cardCvc = this.elements.create('cardCvc', settings);
    this.cardCvc.mount(this.cardCvcInput.nativeElement);
    this.cardCvc.addEventListener('change', this.cardCvcHandler);
  }

  ngOnDestroy() {
    this.cardNumber.removeEventListener('change', this.cardNumberHandler);
    this.cardNumber.destroy();
    this.cardExpiry.removeEventListener('change', this.cardExpiryHandler);
    this.cardExpiry.destroy();
    this.cardCvc.removeEventListener('change', this.cardCvcHandler);
    this.cardCvc.destroy();
  }

  onChange({error}) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  onSubmit(form: NgForm) {
    this.loading = true;

    this.stripe.createToken(this.cardNumber).then((result, error) => {
      this.loading = false;

      if (error) {
        this.error = error;
      } else {
        this.paymentService.sendPayment(this.adId, this.product.id, result.token.id).subscribe(
          (result) => {
            if (result.success) {
              this.success = true;
            }
            else {
              this.error = 'Betaling feilet. Melding fra betalingstjenesten: ' + result.msg;
            }
          },
          (error) => {
            this.error = 'Betaling feilet. Melding fra betalingstjenesten: ' + error.msg;
          }
        );
      }
    });
  }

}
