
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ReplaySubject, Observable } from 'rxjs';

export interface IOtherAd
{
  id: number;
  imgId: number;
  price: number;
  title: string;
}

@Injectable({
  providedIn: 'root'
})

export class OtheradsService {
  private doneCustomers : {
    [customerid: number]: {
      [type: string]: {
        data: ReplaySubject<IOtherAd[]>,
        observable: Observable<IOtherAd[]>
      }
    }
  } = {};

  private count = 0;

  constructor( private http: HttpClient ) { }

  /**
   * Get related ads for a customer.
   *
   * @param type
   * @param customerId
   */
  getAds(type: string, customerId: number) : Observable<IOtherAd[]>
  {
    // If this is the first time fetching for this customer, set up the observable and
    // run the HTTP query. Otherwise returned the cached observable.
    if (!this.doneCustomers[customerId] || !this.doneCustomers[customerId][type]) {
      const replaySubject = new ReplaySubject<IOtherAd[]>(1);

      const newData = {
        data: replaySubject,
        observable: replaySubject.asObservable(),
      };

      if (!this.doneCustomers[customerId]) {
        this.doneCustomers[customerId] = {};
      }

      this.doneCustomers[customerId][type] = newData;

      this.http.get(`${environment.apiUrl}/a/otherAds?id=${customerId}&type=${type}`).subscribe(
        (result: IOtherAd[]) => {
          this.doneCustomers[customerId][type].data.next(result);
        }
      );
    }

    return this.doneCustomers[customerId][type].observable;
  }
}
