import { Component, OnInit } from '@angular/core';
import { SavedSearch, SavedSearchesService } from '../../../global/services/saved-searches.service';
import { NotificationService } from '../../../global/services/notification.service';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '../../../global/comp/confirmation-dialog/confirmation-dialog.component';
import { AuthenticationService } from '../../../global/services/authentication.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-saved-searches',
  templateUrl: './saved-searches.component.html',
  styleUrls: ['./saved-searches.component.scss']
})
export class SavedSearchesComponent implements OnInit {
  savedSearches$: Observable<SavedSearch[]>;

  dialogRef: MatDialogRef<ConfirmationDialogComponent>;

  intervalOptions = [
    { id: 'hourly', name: 'Hver time' },
    { id: 'daily', name: 'Daglig' },
    { id: 'everyotherday', name: 'Annenhver dag' },
    { id: 'weekly', name: 'Ukentlig' },
  ];

  constructor(
    private savedSearchesService: SavedSearchesService,
    private notificationService: NotificationService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.savedSearches$ = this.savedSearchesService.getSavedSearches();
  }

  toggleEmailNotifications(search) {
    const newStatus = search.status === 'active' ? 'disabled' : 'active';
    this.savedSearchesService.updateStatus(search, newStatus).subscribe(
      (result) => {
        this.notificationService.successMessage('Innstillingen ble oppdatert.');
      }
    );
  }

  updateInterval(search) {
    this.savedSearchesService.updateSearch(search).subscribe(
      (result) => {
        this.notificationService.successMessage('Innstillingen ble oppdatert.');
      }
    );
  }

  removeSavedSearch(search) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = 'Er du sikker på at du vil slette søket "' + search.name + '"?';

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.savedSearchesService.deleteSearch(search).subscribe(
          () => {
            this.notificationService.successMessage('Søket har blitt slettet.');
          },
          (error) => {
            this.notificationService.errorMessage('Kunne ikke slette søk.');
          }
        );
      }
      this.dialogRef = null;
    });
  }
}
