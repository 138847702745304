import { Component, OnInit, ViewChild, Input } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { IImage } from '../../services/prospect.service';
import { ProspectEditService, ProspectEditSession } from '../../services/prospect-edit.service';
import { NotificationService } from 'src/app/global/services/notification.service';
import { Subject } from 'rxjs';
import { debounce, debounceTime } from 'rxjs/operators';

import  *  as Flow from '@flowjs/flow.js';
import { text } from '@angular/core/src/render3/instructions';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  @Input() session: ProspectEditSession;
  @ViewChild('uploadBrowse') uploadBrowse;

  textChangeSubject: Subject<any>;

  images: any[];

  constructor(
    private prospectEdit: ProspectEditService,
    private notificationService: NotificationService
  ) {
    // Set up autosaving of image text changes.
    this.textChangeSubject = new Subject();
    this.textChangeSubject.pipe(
      debounceTime(1000)
    ).subscribe(image => {
      this.prospectEdit.setImageText(image.id, image.desc).subscribe(
        (response) => {},
        (error) => { this.notificationService.errorMessage('Kunne ikke lagre bildetekst.'); }
      );
    });
  }

  ngOnInit() {
    this.images = this.session.data.images;

    const currentUser = JSON.parse(localStorage.getItem('userAuth2'));

    var upload = new Flow({
      target: this.prospectEdit.getUploadUrl(),
      query: { esid: this.session.esid },
      headers: { 'Authorization': `Bearer ${currentUser.token}` },
      //chunkSize: 2e+7
    });

    upload.assignBrowse(this.uploadBrowse.nativeElement);

    // Start upload automatically when files are submitted
    upload.on('filesSubmitted', (file) => {
      upload.upload();
    });

    // Verify the file when it's done uploading
    upload.on('fileSuccess', (file, message) => {
      const params = {
        esid: this.session.esid,
        id: file.uniqueIdentifier,
        size: file.size,
        numchunks: file.chunks.length
      };

      this.prospectEdit.verifyUpload(params).subscribe(
        (response) => {
          const image = new IImage;
          image.id = response.id;
          image.desc = '';

          this.images.push(image);
        },
        (error) => {
          console.log(error);
          if (error.status === 415) {
            this.notificationService.errorMessage('Opplasting av bilde feilet: ikke et bilde.');
          }
          else if (error.status === 416) {
            this.notificationService.errorMessage('Opplasting feilet: Filen er for stor.');
          }
          else if (error.status === 413) {
            this.notificationService.errorMessage('Opplasting feilet: Filen er for stor.');
          }
          else {
            this.notificationService.errorMessage('Opplasting av bilde feilet.');
          }
        }
      );
    });

    upload.on('fileError', (file, message) => {
      console.log(file, message);
      this.notificationService.errorMessage('Opplasting av bilde feilet. Filen er for stor.');
    });
  }

  textChanged(event, image: IImage) {
    this.textChangeSubject.next(image);
  }

  deleteImage(image: IImage) {
    this.prospectEdit.deleteImage(image.id).subscribe(
      (response) => {
        this.images = response.images;
      },
      (error) => {
        this.notificationService.errorMessage('Kunne ikke slette bilde.');
      }
    );
  }

  moveImage(image: IImage, newPosition: number) {
    this.prospectEdit.moveImage(image.id, newPosition).subscribe(
      (response) => {
        this.images = response.images;
      },
      (error) => {
        this.notificationService.errorMessage('Kunne ikke flytte bilde.');
      }
    );
  }
}
