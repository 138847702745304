
import { Component, OnInit } from '@angular/core';
import {CurrSearchService} from '../../services/curr-search.service';


@Component({
  selector: 'app-search-root',
  templateUrl: './search-root.component.html',
  styleUrls: ['./search-root.component.css']
})
export class SearchRootComponent implements OnInit {


  constructor( currSearchSvc: CurrSearchService ) {
    currSearchSvc.resetCurrSearch();
  }


  ngOnInit() {
  }
}


