import {Inject, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrSearchService } from './curr-search.service';
import {Search} from '../classes/search';
import { IProspectAdData } from 'src/app/user/services/prospect.service';
import { DOCUMENT } from '@angular/common';

//
// Temporary randomness for fields to come..
//
const randomTitles = [
  'Nissan Qashqai 1.5 Tekna Elegance Skinn 360* kamera',
  'Volvo V40 D2 Momentum Sport VOC, Webasto, DAB, Ryggekamera++',
  'Volvo V90 D3 150hk Inscription AWD aut head-up, DAB+, VOC.',
  'BMW X5',
  'Nissan Leaf',
  'BMW 3-serie 318D GT 143hk Sportline/Panorama/Navi Proff/DAB+'
];

function randReview() {
  return  Math.floor(Math.random() * (9 - 0 + 1)) + 0 + Math.round(Math.random() * 10) / 10;
}

function randomReview(): Ireview {
  return {
    sum: randReview(),
    fun: randReview(),
    reliable: randReview(),
    practical: randReview(),
    economy: randReview(),
    consumption: randReview(),
  };
}

export function getRelatedProspects(prospect): Array<IRelatedProspectData> {
  const related = [];

  for (let i = 1; i < 6; i++) {
    related.push({
      aditemid: prospect.aditemid,
      title: prospect.typedata.brand + ' ' + prospect.typedata.model,
      mainImageId: prospect.images.length > 0 ? prospect.images[i % prospect.images.length].id : null,
      price: prospect.price
    });
  }

  return related;
}

@Injectable({
  providedIn: 'root'
})

//
//
//
export class ViewService {

  private host: string;
  private referrer: string;

  constructor( @Inject(DOCUMENT) private document:any, private http: HttpClient, private currSearchSvc: CurrSearchService ) {
    this.host = environment.apiUrl;


    this.referrer = this.document['referrer'] || this.document['referer'];
  }

  getPrevNext( id: number ): (string|null)[] {
    const ret = [null, null];

    if (!this.currSearchSvc.got()) {
      return ret;
    }

    const s = this.currSearchSvc.get();

    if (!s.res) {
      return ret;
    }

    const d = s.res.data;

    for (let c = 0; c < d.length; c++ ) {
      const t = d[c];
      if (t.aditemid === id ) {
        if ( c > 0 ) {
          ret[0] = '/view/' + d[c - 1].aditemid;
        }
        if (d.length > c + 1 ) {
          ret[1] = '/view/' + d[c + 1].aditemid;
        }
        return ret;
      }
    }

    return ret;
  }



  getViewData( id: number, ref = '' ): Observable<IviewData> {
    let url = this.host + '/a/view?idlist=' + id;

    if ( this.referrer ) {
      url = url + ['&', 'ref', '=', this.referrer].join('');

      this.referrer = '';
    }

    return this.getProspectData(url);

  }

  getPreviewData(sessionId: string): Observable<IviewData> {
    const url = this.host + '/e/edit/getpreview?esid=' + sessionId;
    return this.getProspectData(url, true);
  }

  getProspectData(url, preview = false): Observable<IviewData> {
    return this.http.get(url).pipe
    (
      map((res: any) => {
        if (res.isPreview || res.length > 0) {
          const t: IprospectData = preview ? res : res[0];

          t.review = randomReview();
          t.relatedProspects = getRelatedProspects(t);

          if (t.type == 'hmaAuto') {
            t.badges = this.getBadges(t);
          }

          if (t.typedata.euControlDate) {
            t.typedata.euControlDate = t.typedata.euControlDate.replace('Z', '');
          }
          else {
            t.typedata.euControlDate = null;
          }

          const links = preview ? [null, null] : this.getPrevNext(t.aditemid);

          return {
            adData: t,
            nextLink: links[1],
            prevLink: links[0]
          };
        } else {
          throw new Error('Could not find prospect: ' + url );
        }
      })
    );
  }

  getRelated(prospect: IprospectData, limit: number = 10): Observable<IRelatedProspectData[]> {
    const params = {
      't': prospect.type,
      'b': prospect.typedata.brand,
      'm': prospect.typedata.model,
      'p': '' + prospect.price,
      'num': '' + limit,
      'notid': '' + prospect.aditemid,
      // 'cid': prospect.isPrivate ? '' : '' + prospect.customerid
      'cid': prospect.source !== 104 ? '' : '' + prospect.customerid
    };

    return <Observable<any>>this.http.get(`${environment.apiUrl}/s/similar`, { params: params}).pipe
    (
      map((res: any) => {
        res.data.forEach(element => {
          element.imgId = element.mainImageId;
          element.id = element.aditemid;
        });

        res.data = res.data.filter(element => element.id != prospect.aditemid);

        return res.data;
      })
    );
  }

  getBadges(ad: IprospectData) {
    const sixMonthsAgo = Date.now() - (60 * 60 * 24 * 30 * 6 * 1000),
          nafReportRecent =  ad.typedata.nafControlDate !== null && new Date(ad.typedata.nafControlDate).getTime() > sixMonthsAgo;

    const badge = {
      euControlRecent:  ad.typedata.euControlRecent,
      serviced:         ad.typedata.serviced || ad.typedata.hasHistory,
      hasTestOrReport:  nafReportRecent || ad.typedata.hasQualityReport,
      hasWarranty:      ad.typedata.hasWarranty,
      isVendor:         !ad.isPrivate,
    };

    return badge;
  }
}


export interface Icontact {
  name: string;
  addr: string;
  postNo: string;
  place: string;
}

interface Iimage {
  id: number;
  name: string;
}


export interface Ilocation {
  lat: number;
  lon: number;
  approx: boolean;
  source: string;
}


export interface Itypedata{
  brand: string;
  category: string;
  chassis: string;
  chassisType: string;
  co2gkm: number;
  colorInterior: string;
  colour: string;
  colourDesc: string;
  elBatteryRange?:  number;
  elBatteryCapacity?:  number;
  elMaxPower?: number;
  elNumEngines?:  number;
  engineCCM: number;
  enginePower: number;
  equipment: Iequipment[];
  equipmentCustom: string[];
  euControlDate: string;
  euControlRecent: boolean;
  firstReg: string;
  fuelConsumptionAverage: number;
  fueltype: string;
  boatMaterial: string; 
  boatType: string; 
  motorIncluded: boolean; 
  motorType: string;
  usageTime: number;
  motorBrand: string;
  group: string;
  hasHistory: boolean;
  hasQualityReport: boolean;
  hasWarranty: boolean;
  hideRegNo: boolean;
  length: number;
  maxTrailerWeightWithBrakes: number;
  mileage: number;
  model: string;
  nafControlDate: string;
  numDoors: number;
  numOwners: number;
  numSeats: number;
  numSleepers: number;
  options: [string];
  qualityReport: string;
  regClass: string;
  regNo: string;
  regNoVIN: string;
  serviced: boolean;
  subCategory: string;
  transmission: string;
  transmissionDesc: string;
  title: string;
  variant: string;
  warrantyKm: number;
  warrantyMonths: number;
  warrantyText: string;
  weight: number;
  wheeldrive: string;
  wheeldriveDesc: string;
  width: number;
  xExternalURL: string;
  yearmodel: number;
}

export interface Iequipment {
  id: number;
  name: string;
  category: string;
}

export interface Ireview {
  sum: number;
  fun: number;
  reliable: number;
  practical: number;
  economy: number;
  consumption: number;          // fuel/energy
}

export interface IprospectData {
  equipment: Iequipment[];
  equipmentCustom: string[];
  aditemid: number;
  contact: Icontact;
  customerid: number;
  freetext: string;
  review?: Ireview;
  images: [Iimage];
  isActive: boolean;
  isPrivate: boolean;
  lastModifiedAll: string;
  lastModifiedUser: string;
  location: Ilocation;
  mainImageId: number;
  price: number;
  regPrice: number;
  priceEx: number;
  regPriceExcem: boolean;
  regPriceIncluded?: boolean;
  schemaVer: number;
  source: number;
  timePublished: string;
  type: string;
  typedata: Itypedata;
  relatedProspects: Array<IRelatedProspectData>;
  badges: IProspectBadges;
  sold: boolean;
  swap: { swappable: boolean; swapDesc: string; };
  videoUrl: string;
  saletype: 'forsale' | 'leasing';
  datasrc: string;
  status: string;
  extId?: string;
}

export interface IRelatedProspectData {
  km:             number;
  yearmodel:      number;
  ccounty:        string;
  sellerName:     string;
  mainImageId:    number;
  imgId:          number;
  isPrivate:      boolean;
  type:           string;
  isActive:       boolean;
  timePublished:  string;
  timeModified:   string;
  aditemid:       number;
  id:             number;
  price:          number;
  variant:        string;
  model:          string;
  sellerType:     number;
  brand:          string;
  source:         number;
}

export interface IviewData {
  adData: IprospectData;
  prevLink: string | null;
  nextLink: string | null;
}

export interface IProspectBadges {
  serviced:         boolean;
  euControlRecent:  boolean;
  hasTestOrReport:  boolean;
  hasWarranty:      boolean;
}
