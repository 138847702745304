import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContactService, MessageDetails } from '../../services/contact.service';
import { FormControl, FormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { emailOrPhoneValidator } from '../../../global/validators/email-or-phone.validator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/global/services/notification.service';

declare let gtag: Function;

@Component({
  selector: 'app-contact-form-test',
  templateUrl: './contact-form-test.component.html',
  styleUrls: ['./contact-form-test.component.scss']
})
export class ContactFormComponentTest implements OnInit {

  id: number;
  messageDetails: MessageDetails = new MessageDetails;
  submitted = false;
  sent = false;
  captchaKey = environment.reCaptchaKey;

  contactForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.email]),
    phone: new FormControl('', [Validators.pattern('[0-9]{8}')]),
    message: new FormControl('', [Validators.required]),
    recaptcha: new FormControl('', [Validators.required]),
  }, { validators: [emailOrPhoneValidator]});

  constructor(
    private route: ActivatedRoute,
    private contactService: ContactService,
    private notifications: NotificationService
  ) { }

  ngOnInit() {
    this.route.params.subscribe( params => {
      this.id = parseInt( params.id, 10 );
      this.messageDetails.adId = this.id;
    });
  }

  get f() {
    return this.contactForm.controls;
  }

  onSubmit(captchaResponse: string) {
    this.submitted = true;
    const data = this.contactForm.value;
    data.adId = this.id;

    if (!this.contactForm.errors) {
      this.contactService.sendMessage(data, captchaResponse).subscribe(
        (status) => { this.sent = true; },
        (error) => {
          this.submitted = false;
          this.notifications.errorMessage('Kunne ikke sende melding. Prøv igjen senere.');
        }
      );
    }
  }

  trackSendMessageFromFormClick(platform: string) {
    gtag('event', 'send-message-to-seller', {
      'event_category': 'Social',
      'event_label': platform,
      'transport_type': 'beacon'
    });
  }


}
