import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TemplateDataService {
  showFooter = true;

  setShowFooter(showFooter) {
    this.showFooter = showFooter;
  }

  getShowFooter() {
    return this.showFooter;
  }
}
