import { Injectable } from '@angular/core';
import { IprospectData } from 'src/app/search/services/view.service';
import { BroomImagesService } from './broom-images.service';
import { HmaTypeService } from './hma-type.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LinkpulseService {
  lp: any;

  constructor(private imageService: BroomImagesService, private hmaTypeService: HmaTypeService) {
    this.lp = window['LP4'];

    if( !this.lp ) {
      setTimeout(() => {
        this.lp = window['LP4'];
      }, 2000 );
    }
  }

  /**
   * Track a pageview based on page settings.
   */
  trackPageview(settings: any) {
    settings.url = `${window.location.origin}${settings.url}`;

    if (environment.production ) {
      if( this.lp ) {
        this.lp.logPageview(settings);
      }
    }
    else {
      console.log('Linkpulse tracking', settings);
    }
  }

  /**
   * Track a prospect view.
   */
  trackProspectView(prospect: IprospectData) {
    const settings = {
      url: `/view/${prospect.aditemid}`,
      title: prospect.type == 'hmaOther' ? prospect.typedata.title : `${prospect.typedata.yearmodel} ${prospect.typedata.brand} ${prospect.typedata.model}`,
      image: this.getShareImage(prospect.mainImageId),
      description: prospect.typedata.title || prospect.freetext,
      type: 'article',
      section: this.hmaTypeService.getName(prospect.type),
      tag: this.getTags(prospect),
      published: new Date(prospect.timePublished).toISOString(),
      modified: new Date(prospect.lastModifiedAll).toISOString(),
    };

    this.trackPageview(settings);
  }

  /**
   * Get an image for sharing/tracking.
   */
  getShareImage(imageId: number) {
    return this.imageService.getImageUrl(imageId, 800);
  }

  /**
   * Get the tags for a prospect, based on misc criteria.
   */
  getTags(prospect: IprospectData): string[] {

    let tags = [
      prospect.typedata.brand || 'Ukjent merke', // Default if brand is missing,
      prospect.typedata.model || 'Ukjent modell', // Default if model is missing,
      prospect.typedata.yearmodel ? prospect.typedata.yearmodel.toString() : 'Ukjent år', // Default if year is missing
      prospect.typedata.variant || 'Ingen beskrivelse'// Default if variant is missing
    ];

    if (prospect.type == 'hmaAuto') {
      if (prospect.typedata.fueltype == 'Elektrisitet') {
        tags.push('Elbil');
      }
      if (prospect.typedata.chassis == 'Suv/offroad') {
        tags.push('SUV');
      }
      if (prospect.typedata.enginePower >= 350) {
        tags.push('Sportsbil');
      }
      if (prospect.price >= 1000000) {
        tags.push('Luksusbil');
      }
      if (prospect.price < 100000) {
        tags.push('Billigbil');
      }
    }

    return tags;
  }
}
