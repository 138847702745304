import { Component, OnInit, Input, OnChanges, ElementRef, ViewChild, HostListener } from '@angular/core';
import { ImageSliderComponent } from '../image-slider/image-slider.component';
import { WindowSizeService } from '../../services/window-size.service';
import { WindowRefService } from '../../services/window-ref.service';

@Component({
  selector: 'app-fullscreen-slider',
  templateUrl: './fullscreen-slider.component.html',
  styleUrls: ['./fullscreen-slider.component.scss']
})
export class FullscreenSliderComponent implements OnInit {

  @Input() images: any[];
  @Input() videoUrl: string|null;
  @Input() startingSlide = 0;

  @ViewChild(ImageSliderComponent) mainSlider: ImageSliderComponent;

  mainSliderWidth: number;
  sliderFixedHeight: number;

  el: ElementRef;

  get activeSlide() {
    if (!this.mainSlider) {
      return 0;
    }

    return this.mainSlider.index;
  }

  get countGroup() {
    if (!this.images || this.images.length <= 15) {
      return 15;
    } else if (this.images.length < 30) {
      return 30;
    } else if (this.images.length < 50) {
      return 50;
    }

    return 100;
  }

  constructor(el: ElementRef, public windowSize: WindowSizeService, private windowRef: WindowRefService) {
    this.el = el;
  }

  ngOnInit() {
    if (this.windowSize.checkScreenSize('mobile')) {
      this.sliderFixedHeight = this.windowRef.nativeWindow.innerHeight - 120;
    } else {
      const nativeElement = this.el.nativeElement;
      const width = (this.windowRef.nativeWindow.innerHeight - 200) / .75;

      this.mainSliderWidth = width;
    }
  }

  gotoSlide(index: number) {
    this.mainSlider.gotoSlide(index);
  }
}
