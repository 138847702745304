import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProspectEditService, ProspectSession } from '../../services/prospect-edit.service';
import { NotificationService } from '../../../global/services/notification.service';
import { HmaType, HmaTypeService } from 'src/app/global/services/hma-type.service';

@Component({
  selector: 'app-create-prospect',
  templateUrl: './create-prospect.component.html',
  styleUrls: ['./create-prospect.component.scss']
})
export class CreateProspectComponent implements OnInit {
  types: HmaType[];

  sessions$: Observable<ProspectSession>;

  constructor(
    private router: Router,
    private prospectEdit: ProspectEditService,
    private notificationService: NotificationService,
    private hmaTypeService: HmaTypeService
  ) {
    this.types = hmaTypeService.getEditEnabledTypes();
  }

  ngOnInit() {
    this.sessions$ = this.prospectEdit.getProspectSessions();
  }

  createProspect(type) {
    this.prospectEdit.createSession(type).subscribe(
      (session) => {
        console.log(session);
        this.router.navigate(['/bruker/annonser/rediger', session.esid, 'new']);
      },
      (error) => {
        this.notificationService.errorMessage('Kunne ikke opprette ny annonse. Vennligst prøv igjen, eller meld fra ved feil.');
      }
    )
  }

}
