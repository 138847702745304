import { Injectable } from '@angular/core';
import { Router, Event, NavigationStart } from '@angular/router';
import { Location, ViewportScroller } from '@angular/common';
import { ViewportScrollPosition } from '@angular/cdk/scrolling';

@Injectable({
  providedIn: 'root'
})
export class ScrollPosService {

  history: { url: string; element: string; }[] = [];

  constructor(private router: Router, private location: Location, private scroller: ViewportScroller) { }

  /**
   * Store a scroll-to element for the current URL.
   * Stores a maximum of 3 elements; first in first out.
   */
  storeScroll(elementId: string) {
    this.history = this.history.slice(0, 2);
    this.history.unshift({
      url: this.location.path(),
      element: elementId
    });
  }

  /**
   * Restore the scroll position for the current page.
   */
  restoreScroll() {
    const position = this.history.find(el => el.url == this.location.path());

    if (position) {
      this.scroller.scrollToAnchor(position.element);
    }
  }
}
