import {Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import {SearchArgMultiVal, ISearchArgMultiValNode} from '../../../classes/search-args';
import {ResourceService} from '../../../../global/services/resource.service';
import { ActivatedRoute } from '@angular/router';
import { skip } from 'rxjs/operators';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-inp-multi-val',
  templateUrl: './inp-multi-val.component.html',
  styleUrls: ['./inp-multi-val.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InpMultiValComponent implements OnInit, OnDestroy {

  listeners: Subscription[] = [];

  @Input() arg: SearchArgMultiVal;
  @Input() label: string;
  @Input() popularFilter = false;
  @Input() showInput = false;

  showUnpopular = false;

  nodes: ISearchArgMultiValNode[];
  selectedValues: any[];

  inputsLoaded = false;

  constructor (
    private resSvc: ResourceService,
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef
  ) { }

  get availableNodes() {
    const nodes = !this.popularFilter || this.showUnpopular || !this.nodes ? this.nodes : this.nodes.filter(
      item => item.popular || item.selected
    );

    if (!nodes) {
      return [];
    }

    return nodes;
  }

  ngOnInit() {
    this.updateNodes();

    let listener = this.arg.observeNodeChanges().subscribe(
      (nodes) => {
        this.updateNodes();
      }
    );

    this.listeners.push(listener);

    listener = this.activatedRoute.queryParams.pipe(skip(1)).subscribe(
      (params) => {
        this.updateNodes();
      }
    );

    this.listeners.push(listener);
  }

  updateNodes() {
    this.arg.getNodes().subscribe((nodes) => {
      this.nodes = nodes;

      if (this.showInput && !this.inputsLoaded) {
        this.inputsLoaded = true;
      }

      this.selectedValues = [];

      nodes.filter(node => node.selected).forEach(node => {
        let selectedNode: any = {
          name: node.name,
          children: node.children ? node.children.filter(child => child.selected) : [],
        };

        selectedNode.childList = selectedNode.children.length == 0
          ? ''
          : '(' + selectedNode.children.map(c => c.name).join(', ') + ')';

        this.selectedValues.push(selectedNode);
      });

      this.changeDetector.detectChanges();
    });
  }

  onChange(checked: boolean, argVal: string, childVal: string | null ) {
    this.arg.setVal( argVal, childVal, checked );
    this.updateNodes();
  }

  toggleShowInputs() {
    this.showInput = !this.showInput;

    if (this.showInput && !this.inputsLoaded) {
      this.inputsLoaded = true;
    }
  }

  ngOnDestroy() {
    this.listeners.forEach(listener => listener.unsubscribe());
  }
}



