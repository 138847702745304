import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public snackBar: MatSnackBar) { }

  errorMessage(message: string, hideDelay: number | boolean = true) {
    if (hideDelay === false) {
      hideDelay = 0;
    }

    if (hideDelay === true) {
      this.snackBar.open(message);
    }
    else {
      this.snackBar.open(message, 'OK', { duration: hideDelay });
    }
  }

  successMessage(message: string) {
    this.snackBar.open(message);
  }
}
