import { ValidatorFn, AbstractControl } from '@angular/forms';

export function numberValidator(allowDecimals: boolean): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (control.value == '' || control.value === null) {
      return null;
    }

    const regex = allowDecimals ? /^[0-9]+((\.|,)[0-9]+)?$/ : /^[0-9]+$/;
    const numeric = regex.test(control.value);

    return !numeric ? {'number': {value: control.value}} : null;
  };
}
