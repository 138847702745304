import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import 'hammerjs';

@Injectable({
  providedIn: 'root'
})
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    pan: {
        direction: 6
    },
    pinch: {
        enable: false
    },
    rotate: {
        enable: false
    }
  };
}
