import { Component, OnInit, OnDestroy, Input, ElementRef, HostBinding, Optional, Self } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss'],
  providers: []
})
export class InputPasswordComponent implements OnInit {
  static nextId = 0;
  showPassword = false;

  @Input() form: FormGroup;
  @Input() placeholder: string;
  @Input() inputName: string = 'password';
  @Input() checkStrength: boolean;

  constructor() { }

  ngOnInit() {
    let validators = [Validators.required];

    if (this.checkStrength) {
      validators.push(Validators.minLength(6));
    }

    this.form.get(this.inputName).setValidators(validators);
  }
}
