import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bigNumber'
})
export class BigNumberPipe implements PipeTransform {
  transform(value: number | string): string | number {
    const locale = 'nb-NO';

    const num = typeof value == 'string' ? parseInt(value) : value;

    if (num === NaN || num < 1000) {
      return value;
    }

    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0
    }).format(Number(value));
  }
}
