/*
	A passive class, container and validator of search-args
*/

import { SearchArg, SearchArgMultiVal, SearchArgs } from './search-args';
import { SearchService, IsearchResult } from '../services/search.service';
import { IRelatedProspectData } from '../services/view.service';
import { map } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { ResourceService } from './../../global/services/resource.service';
import { OtheradsService } from '../services/otherads.service';


export class Search {
  args: SearchArgs;
  res: IsearchResult;

  constructor (
    private searchSvc: SearchService,
    private resourceSvc: ResourceService,
    private otherAds : OtheradsService,
    args?: {} | string
  ) {
    this.args = new SearchArgs(resourceSvc);
  }

  getArg(name, val?: string): SearchArg {
    return this.args.getArg(name, val);
  }

  getAllArgs(values = {}): {} {
    const args = {};
    [
      'type',
      'price',
      'limit',
      't',
      'km',
      'range',
      'yearmodel',
      'usageTime',
      'hk',
      'sort',
      'page',
      'b',
      'source',
      'category',
      'boatType',
      'numsleepers',
      'numseats',
      'fueltype',
      'boatMaterial',
      'motorIncluded',
      'motorType',
      'forhandler',
      'wheeldrive',
      'trailerweight',
      'transmission',
      'sellertype',
      'saletype',
      'chassis',
      'fylke',
      'colour',
      'ccm',
      'weight',
      'length',
      'width',
      'condition',
      'equipment',
    ].forEach((name: string) => {
      args[name] = this.getArg(name, values[name] || '');
    });

    this.args.observeArgChanges().subscribe(() => { console.log('CHANGED'); });
    return args;
  }

  doSearch() {
    return this.searchSvc.doSearch(this.args.toParams()).pipe(
      map((res: IsearchResult) => {
        this.res = res;
        return res;
      })
    );
  }

  setType(type: string) {
    this.args.onTypeChange(type);
  }
}
