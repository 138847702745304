import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../../global/guards/auth.guard';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';
import { MatStepperModule } from '@angular/material/stepper';

import { SharedModule } from '../../shared/shared.module';
import { environment } from '../../../environments/environment';

import { LoginComponent } from '../comp/login/login.component';
import { RegisterComponent } from '../comp/register/register.component';
import { ValidateUserComponent } from '../comp/validate-user/validate-user.component';
import { DashboardComponent } from '../comp/dashboard/dashboard.component';
import { SavedProspectsComponent } from '../comp/saved-prospects/saved-prospects.component';
import { SavedSearchesComponent } from '../comp/saved-searches/saved-searches.component';
import { RegisterDetailsComponent } from '../comp/register-details/register-details.component';
import { AccountDetailsComponent } from '../comp/account-details/account-details.component';
import { MyProspectsComponent } from '../comp/my-prospects/my-prospects.component';
import { ProspectStatsComponent } from '../comp/prospect-stats/prospect-stats.component';
import { CreateProspectComponent } from '../comp/create-prospect/create-prospect.component';
import { EditProspectComponent } from '../comp/edit-prospect/edit-prospect.component';
import { FileUploadComponent } from '../comp/file-upload/file-upload.component';
import { ProspectPublishComponent } from '../comp/prospect-publish/prospect-publish.component';
import { ProspectPublishedComponent } from '../comp/prospect-published/prospect-published.component';
import { ProspectPaymentComponent } from '../comp/prospect-payment/prospect-payment.component';
import { ResetPasswordComponent } from '../comp/reset-password/reset-password.component';
import { SetPasswordComponent } from '../comp/set-password/set-password.component';
import { ReceiptsComponent } from '../comp/receipts/receipts.component';
import { ViewReceiptComponent } from '../comp/view-receipt/view-receipt.component';
import {MatCardModule} from '@angular/material';
import {ProspectSmsValidateComponent} from "../comp/prospect-sms-validate/prospect-sms-validate.component";
import { UserMenuComponent } from '../comp/user-menu/user-menu.component';

const routes: Routes = [
  { path: 'validerepost/:uuid/:code', component: ValidateUserComponent },
  {
    path: 'bruker',
    children: [
      { path: '', redirectTo: '/bruker/min-side', pathMatch: 'full' },
      { path: 'logg-inn', component: LoginComponent },
      { path: 'glemt-passord/:email', component: ResetPasswordComponent },
      { path: 'glemt-passord', component: ResetPasswordComponent },
      { path: 'nytt-passord', component: SetPasswordComponent },
      { path: 'nytt-passord/:uuid/:token', component: SetPasswordComponent },
      { path: 'registrer', component: RegisterComponent },
      { path: 'validerepost', component: ValidateUserComponent },
      { path: 'registrer-ekstra', component: RegisterDetailsComponent, canActivate: [AuthGuard] },
      { path: 'min-side', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'konto', component: AccountDetailsComponent, canActivate: [AuthGuard] },
      { path: 'kvitteringer', component: ReceiptsComponent, canActivate: [AuthGuard] },
      { path: 'kvitteringer/:id', component: ViewReceiptComponent, canActivate: [AuthGuard] },
      { path: 'favoritter', component: SavedProspectsComponent, canActivate: [AuthGuard] },
      { path: 'sok', component: SavedSearchesComponent, canActivate: [AuthGuard] },
      {
        path: 'annonser',
        canActivate: [AuthGuard],
        children: [
          { path: '', component: MyProspectsComponent },
          { path: 'opprett', component: CreateProspectComponent },
          { path: 'rediger/:id/sms-valider', component: ProspectSmsValidateComponent },
          { path: 'rediger/:id/valg', component: ProspectPublishComponent },
          { path: 'rediger/:id/valg/:mode', component: ProspectPublishComponent },
          { path: 'publisert/:id', component: ProspectPublishedComponent },
          { path: 'rediger/:sessionId', component: EditProspectComponent },
          { path: 'rediger/:sessionId/:mode', component: EditProspectComponent },
          { path: 'rediger/:id/betal/:productId', component: ProspectPaymentComponent },
          { path: ':id/statistikk', component: ProspectStatsComponent },
        ]
      },
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forRoot(routes, {enableTracing: environment.navigationDebug}),
    FormsModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    MatStepperModule,
    MatCardModule,
  ],
  declarations: [
    LoginComponent,
    RegisterComponent,
    ValidateUserComponent,
    RegisterDetailsComponent,
    DashboardComponent,
    SavedProspectsComponent,
    SavedSearchesComponent,
    AccountDetailsComponent,
    MyProspectsComponent,
    ProspectStatsComponent,
    CreateProspectComponent,
    EditProspectComponent,
    FileUploadComponent,
    ProspectPublishComponent,
    ProspectPublishedComponent, 
    ProspectPaymentComponent,
    ProspectSmsValidateComponent,
    ResetPasswordComponent,
    SetPasswordComponent,
    ReceiptsComponent,
    ViewReceiptComponent,
    UserMenuComponent
  ],
  providers: [
    AuthGuard
  ]
})
export class UserModule { }
