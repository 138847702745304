import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { SearchArgSingle, ISearchArgMultiValNode, SearchArgMultiVal } from '../../../classes/search-args';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-inp-button-list',
  templateUrl: './inp-button-list.component.html',
  styleUrls: ['./inp-button-list.component.css']
})
export class InpSingleSelectComponent implements OnInit {

  @Input() arg: SearchArgSingle;
  @Input() layout: 'select' | 'buttons' = 'select';
  @Input() label: string;
  @Input() options: { name: string; value: string | number }[];
  @Input() extraParams: { [fieldName: string]: string; };

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
  }

  onChange() {
    this.arg.onChange(this.arg.val);
  }

  setValue(newValue: string) {
    const queryParams = {};
    queryParams[this.arg.name] = newValue;

    // If redirecting from the front page to the search page, add querystring to keep search filter open
    if (this.activatedRoute.snapshot.url.length == 0) {
      queryParams['openSearch'] = 'true';
    }

    if (this.extraParams) {
      Object.keys(this.extraParams).forEach(key => {
        queryParams[key] = this.extraParams[key];
      });
    }

    this.router.navigate(['/search'], { queryParams: queryParams });
  }
}
