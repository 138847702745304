import { Component } from '@angular/core';
import { TemplateDataService } from './global/services/template-data.service';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { MetaTagsService } from './global/services/metatags.service';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    public templateData: TemplateDataService,
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private metatags: MetaTagsService,
    router: Router
  ) {
    angulartics2GoogleAnalytics.startTracking();
  }
}
