import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewService, IprospectData } from '../../../services/view.service';
import { NotificationService } from '../../../../global/services/notification.service';

@Component({
  selector: 'app-expand-report-modal',
  templateUrl: './expand-report-modal.component.html',
  styleUrls: ['./expand-report-modal.component.scss']
})
export class ExpandReportModalComponent implements OnInit {
  prospect: IprospectData;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    public dialogRef: MatDialogRef<ExpandReportModalComponent>,
    private vs: ViewService,
    private notifications: NotificationService
  ) { }

  ngOnInit() {
    this.vs.getViewData(this.data.id).subscribe(
      (val) => {
        this.prospect = val.adData;
      },
      (error) => {
        this.notifications.errorMessage('Kunne ikke finne annonsen.', false);
      }
    );
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
