import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { IRelatedProspectData } from '../../../search/services/view.service';
import { WindowRefService } from '../../services/window-ref.service';
import { IOtherAd } from '../../../search/services/otherads.service';
import { WindowSizeService } from '../../services/window-size.service';

@Component({
  selector: 'app-prospect-slider',
  templateUrl: './prospect-slider.component.html',
  styleUrls: ['./prospect-slider.component.scss']
})
export class ProspectSliderComponent implements OnInit {

  config: object = {
    slidesPerView: 'auto'
  };

  @Input() prospects: any[] = [];
  @Input() numberPerRowMedium: number = 4;
  @Input() limit = 5;
  @Input() slidesLimit;
  @Input() loadSlider = true;
  @Input() exclude: number;
  @Input() moreLink: boolean;
  @Input() moreLinkCustomer: number;
  @Input() hmaType: string;

  selectedProspects: any[];

  get showSlider() {
    return this.windowSize.checkScreenSize('mobile');
  }

  constructor(private windowRef: WindowRefService, private windowSize: WindowSizeService) { }

  ngOnInit() {
    const limit = this.showSlider ? (this.slidesLimit || this.limit) : this.limit;

    this.selectedProspects = this.prospects;

    // If there are any prospects to exclude, remove from the array
    if (this.exclude) {
      this.selectedProspects = this.prospects.filter(value => value.id != this.exclude);
    }

    // If there are more prospects than the show limit, select random prospects to show.
    if (this.selectedProspects.length > limit) {
      const shuffled = this.selectedProspects.sort(() => .5 - Math.random());
      this.selectedProspects = shuffled.slice(0, limit);
    }
  }

  setProspectClass() {
    let prospectClass = {};
    prospectClass['medium-' + 12 / this.numberPerRowMedium] = true;
    return prospectClass;
  }
}
