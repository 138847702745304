import { Component, ElementRef, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-prospect-badges',
  templateUrl: './prospect-badges.component.html',
  styleUrls: ['./prospect-badges.component.scss']
})

export class ProspectBadgesComponent implements OnInit {
  @Input() prospect;
  @Input() reportLink: string[];

  numberOfItems: number;
  wrapperClass: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    // This might not work correctly here, as the DOM might not be fully initialized yet
    // this.countNumberOfItems();
    // this.updateWrapperClass();
  }

  ngAfterViewInit() {
    this.countNumberOfItems();
    this.updateWrapperClass();
  }

  countNumberOfItems() {
    const rowElement = this.elementRef.nativeElement.querySelector('.row.collapse');
    if (rowElement) {
      this.numberOfItems = rowElement.children.length;
    }
  }

  updateWrapperClass() {
    this.wrapperClass = `items-${this.numberOfItems}`;
  }
}
