import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, share, publishReplay } from 'rxjs/operators';
import { SavedSearch } from './saved-searches.service';
import { SavedProspect } from './saved-prospects.service';

import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private data = new ReplaySubject<User>(1);
  userData$: Observable<User> = this.data.asObservable();

  fetched = false;

  constructor(private http: HttpClient) { }

  register(user: any, recaptcha: string) {
    const data = {
      e: user.email,
      p: user.password,
      captcha: recaptcha
    };

    return this.http.post(`${environment.apiUrl}/e/register`, data);
  }

  validate(validation) {
    const data = {
      v: validation.uuid,
      c: validation.code,
    };

    return this.http.post(`${environment.apiUrl}/e/emailvalidatecode`, data).pipe(
      map(user => {
        // login successful if there's a jwt token in the response
        if (user && user['x-access-token']) {
            user['token'] = user['x-access-token'];

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('userAuth2', JSON.stringify(user));
        }

        return user;
      }));
  }

  resendValidation(email: string) {
    return this.http.post(`${environment.apiUrl}/e/newemailvalidate`, {'e': email});
  }

  resetPassword(email: string) {
    return this.http.post(`${environment.apiUrl}/e/user/resetpassword`, {'e': email});
  }

  setPassword(uuid: string, token: string, password: string) {
    return this.http.post(`${environment.apiUrl}/e/user/setpassword`, {'vid': uuid, 't': token, pwd: password}).pipe(
      map(user => {
        // login successful if there's a jwt token in the response
        if (user && user['jwt']) {
            user['token'] = user['jwt'];

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('userAuth2', JSON.stringify(user));
        }

        return user;
      }));
  }

  getCurrentUser(forceNewFetch: boolean = false): Observable<User> {
    if (!this.fetched || forceNewFetch) {
      this.http.get(`${environment.apiUrl}/e/getuser`)
        .pipe(
          map((user: User) => {
            if (user) {
              return user;
            } else {
              throw new Error('Could not find user');
            }
          })
        ).subscribe(
          (res) => { this.data.next(res); },
          (error) => this.data.error('Could not find user.')
        );

      this.fetched = true;
    }

    return this.userData$;
  }

  updateUserData(user: User) {
    const params = {
      name: user.name,
      addr: user.addr,
      postCode: user.postCode,
      phoneNo: user.phone,
      city: user.city
    };

    this.fetched = false;

    return this.http.post(`${environment.apiUrl}/e/user/userstuff`, params);
  }

  getCityFromPostCode(postCode: string): Observable<any> {
    const params = {
      clientUrl: environment.apiUrl,
      pnr: postCode
    };

    return <Observable<any>>this.http.get('https://api.bring.com/shippingguide/api/postalCode.json', { params: params });
  }
}

export class User {
  isLoggedIn:      boolean;
  userId:          string;
  email:           { email: string, validated: boolean }[];
  emailValidated:  boolean;
  mobile:          { no: string, val: boolean }[];
  mobileValidated: boolean;
  name:            string;
  savedSearch:     SavedSearch[];
  savedAds:        SavedProspect[];
  addr:            string;
  postCode:        string;
  city:            string;
  phone:           number;
}
