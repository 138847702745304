import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportAbuseService {

  constructor(private http: HttpClient) { }

  sendScamReport(id: string) {
    return this.http.get(`${environment.apiUrl}/x/report/email`, { params: { id: id }});
  }
}
