import { Component, OnInit, Input, ViewChild, ElementRef, ContentChild } from '@angular/core';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-input-optional',
  templateUrl: './input-optional.component.html',
  styleUrls: []
})
export class InputOptionalComponent implements OnInit {

  show = false;

  @Input() value: any;
  @Input() label: string;
  @ContentChild(MatInput) childInput;

  get isEmpty() {
    return !this.value || this.value === null || this.value === '' || this.value.length === 0;
  }

  constructor() { }

  ngOnInit() {
    if (!this.isEmpty) {
      this.show = true;
    }
  }

  toggleView() {
    this.show = true;

    setTimeout(() => {
      if (this.childInput) {
        this.childInput.focus();
      }
    }, 1);
  }

}
