import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ViewService } from '../../../../services/view.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { MetaTagsService } from 'src/app/global/services/metatags.service';
import { IprospectData } from '../../../../services/view.service';

@Component({
  selector: 'app-key-info-auto',
  templateUrl: './key-info-auto.component.html',
  styleUrls: ['./key-info-auto.component.scss']
})
export class KeyInfoAutoComponent implements OnInit, OnDestroy {
  @Input() data: IprospectData;
  id: number;

  constructor(
    private route: ActivatedRoute,
    private vs: ViewService,
    public ngxSmartModalService: NgxSmartModalService,
    private metatags: MetaTagsService,
    
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = parseInt(params.id, 10);
        this.vs.getViewData(this.id).subscribe(
          (val) => {
            this.data = val.adData;
          },
          (error) => {
            console.error('Error fetching data:', error);
          }
        );
      }
    });
  }
  
  ngOnDestroy() {
    this.metatags.clear();
  }
  

}
