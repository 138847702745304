
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { delay, tap, map } from 'rxjs/operators';
import { ISearchArgMultiValNode } from '../../search/classes/search-args';
import { multivalData, popularData } from '../static/multi-val-data';


@Injectable({
  providedIn: 'root'
})

/**
 * Service for receiving resources - data stored either in backend or in static config.
 */
export class ResourceService {
  private multivalData: {
    [type: string]: {
      [name: string]: {
        data: ReplaySubject<ISearchArgMultiValNode[]>,
        observable: Observable<ISearchArgMultiValNode[]>
      },
    }
  } = {};

  constructor( private http: HttpClient ) { }

  /**
   * Get a list of options to use for a multi-value search input.
   *
   * @param name
   * @param type
   */
  getMultiValNodes(name: string, type = 'hmaAuto'): Observable<ISearchArgMultiValNode[]> {
    return this.getMultiValNodesRawJson(name, type).pipe(
      map((values: any[]) => {
        return this.jsonToMultivalNodes(values, popularData[name] && popularData[name][type] ? popularData[name][type]: null);
      })
    );
  }

  getPopData( name : string, type : string ) : string[]
  {
    if( popularData[name] && popularData[name][type])
    {
      return popularData[name][type];
    }
    throw new Error('no name type' );
  }


  /**
   * Get the raw JSON data used for multi-value search inputs.
   *
   * @param name
   * @param type
   */
  getMultiValNodesRawJson(name: string, type: string): Observable<any> {
    if (!this.multivalData[type] || !this.multivalData[type][name]) {
      const replaySubject = new ReplaySubject<ISearchArgMultiValNode[]>(1);

      const newData = {
        data: replaySubject,
        observable: replaySubject.asObservable(),
      };

      if (!this.multivalData[type]) {
        this.multivalData[type] = {};
      }

      this.multivalData[type][name] = newData;

      if (!multivalData[name]) {
        replaySubject.next(null);
      }
      else if (type && multivalData[name][type]) {
        replaySubject.next(multivalData[name][type]);
      }
      else {
        replaySubject.next(multivalData[name]['default']);
      }
    }

    return this.multivalData[type][name].observable;
  }

  /**
   * Convert raw JSON data to Multi-value input nodes.
   *
   * @param data
   * @param popular
   */
  private jsonToMultivalNodes(data: IJsonValues, popular: string[] | null): ISearchArgMultiValNode[]
  {
    const nodes: ISearchArgMultiValNode[] = [];

    if (!data) {
      return nodes;
    }

    for (const mainName of Object.keys(data)) {
      const isObject = !Array.isArray(data[mainName]);
      const kids = isObject
        ? data[mainName].children ? data[mainName].children : []
        : data[mainName];

      const node = {
        name: isObject ? data[mainName].name : mainName,
        value: mainName,
        selected: false,
        children: kids.map((n) => { return {name: n, selected: false }}),
        popular: !popular || (popular && popular.indexOf(mainName) >= 0)
      };

      nodes.push(node);
    }

    return nodes;
  }
}


interface IJsonValues {
  [name: string]: string[] | any;
}

