import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticleService, Article } from 'src/app/articles/services/article.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MetaTagsService } from 'src/app/global/services/metatags.service';

declare let gtag: Function;

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  article: Article | undefined;
  safeContent: SafeHtml | undefined;
  currentUrl: string;

  constructor(
    private route: ActivatedRoute,
    private articleService: ArticleService,
    private sanitizer: DomSanitizer,
    private metaTagsService: MetaTagsService,
  ) {}

  ngOnInit(): void {
    this.currentUrl = window.location.href;
    const slug = this.route.snapshot.paramMap.get('slug'); // Remove the +
    this.article = this.articleService.getArticleBySlug(slug);
    if (this.article) {
      if (this.article.content) {
        this.safeContent = this.sanitizer.bypassSecurityTrustHtml(this.article.content);
      } else if (this.article.contentUrl) {
        this.articleService.getArticleContent(this.article).subscribe((content) => {
          this.safeContent = this.sanitizer.bypassSecurityTrustHtml(content);
        });
      }

      this.metaTagsService.setTitle(this.article.title);
      this.metaTagsService.setTagValues({
        'og:description': this.article.summary,
        'description': this.article.summary,
        'og:image': 'https://autodb.no' + this.article.imageUrl,
      });
      this.metaTagsService.addMetaTags();
    }
  }

  trackShare(platform: string) {
    gtag('event', 'share-news', {
      'event_category': 'Social',
      'event_label': platform,
      'transport_type': 'beacon'
    });
  }

  shareOnFacebook() {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.currentUrl)}`;
    window.open(facebookShareUrl, '_blank');
    this.trackShare('Facebook');
  }

  shareByEmail() {
    const subject = encodeURIComponent(`Jeg ønsker å dele denne artikkelen med deg: ${this.article.title}`);
    const body = encodeURIComponent(
      `Jeg ønsker å dele denne artikkelen fra AutoDB med deg:\n\n` +
      `Tittel: ${this.article.title}\n` +
      `Beskrivelse: ${this.article.summary}\n` +
      `Du kan lese hele annonsen her: ${this.currentUrl}`
    );
    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;

    window.location.href = mailtoLink;
    this.trackShare('Email');
  }

  printPage() {
    window.print();
    this.trackShare('Print');
  }
}
