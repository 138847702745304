import { Component, OnInit, Input, AfterViewChecked } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WindowSizeService } from '../../services/window-size.service';
//import { AdsService } from '../../services/ads.service';

@Component({
  selector: 'app-dfp-ad',
  templateUrl: './dfp-ad.component.html',
  styleUrls: ['./dfp-ad.component.scss']
})
export class DfpAdComponent implements OnInit, AfterViewChecked {
  @Input() name: string;
  @Input() page: string = '';
  @Input() size: 'mobile' | 'desktop';
  @Input() id: string;

  adConfig: any;
  adUnit: string;

  display: boolean = false;
  loaded = false;

  constructor(
    private windowSize: WindowSizeService,
    //private ads: AdsService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.display = this.checkScreenSize();

    this.activatedRoute.url.subscribe(url => {
      setTimeout(() => {
        if (!this.loaded && this.checkScreenSize()) {
          this.loaded = false;
        }
      }, 1000);
    });
  }

  ngAfterViewChecked() {
    if (!this.loaded && this.checkScreenSize()) {
      this.loaded = true;
      //this.ads.loadAd(this.name); Commenting out ads
    }
  }

  /**
   * Checks if the ad should be displayed at the current screen size. If a size is not given,
   * always return true.
   */
  checkScreenSize(): boolean {
    if (!this.size) {
      return true;
    }

    return this.windowSize.checkScreenSize(this.size);
  }
}
