import { Component, OnInit, OnDestroy } from '@angular/core';
import { SavedProspect, SavedProspectsService } from '../../../global/services/saved-prospects.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../../global/services/notification.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-saved-prospects',
  templateUrl: './saved-prospects.component.html',
  styleUrls: ['./saved-prospects.component.scss']
})
export class SavedProspectsComponent implements OnInit, OnDestroy {
  prospects: SavedProspect[];

  constructor(
    private savedProspectsService: SavedProspectsService,
    private router: Router,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.savedProspectsService.getSavedProspects().subscribe(
      (prospects) => { this.prospects = prospects; }
    );
  }


  removeSavedProspect(prospect) {
    this.savedProspectsService.deleteProspect(prospect.aditemid, prospect.imgid, prospect.type).subscribe(
      (result) => {
        this.notificationService.successMessage('Annonsen ble fjernet fra favoritter');
        this.prospects = this.savedProspectsService.savedProspects;
      },
      (error) => {
        this.notificationService.errorMessage('Kunne ikke fjerne fra favoritter.');
      }
    );
  }

  ngOnDestroy() {

  }
}
