import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {map, subscribeOn, tap, catchError, share} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { UserService } from '../../global/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class ProspectService {
  currentSession: string;

  constructor(private http: HttpClient, private userService: UserService) {
  }

  /**
   * Get a list of all prospects.
   */
  getProspects(): Observable<UserProspects> {
    return <Observable<UserProspects>>this.http.get(`${environment.apiUrl}/e/myaditems`);
  }

  /**
   * Get details for one specific prospect.
   *
   * @param id Prospect id
   */
  getProspectDetails(id: string): Observable<IProspect> {
    return <Observable<IProspect>>this.http.get(`${environment.apiUrl}/e/aditemdetails`,
      {params: {id: id}})
      .pipe(share());
  }

  /**
   * Get statistics for a prospect.
   *
   * @param id Prospect ID
   */
  getProspectStats(id: string, numDays = 7): Observable<IProspect> {
    return <Observable<IProspect>>this.http.get(`${environment.apiUrl}/e/aditemstats`,
      {params: {id: id, numdays: numDays + ''}})
      .pipe(share());
  }

  /**
   * Get the current status of a prospect.
   *
   * @param id
   */
  getProspectStatus(id: string | number) {
    return this.http.get(`${environment.apiUrl}/e/edit/getstatus?aditemid=${id}`);
  }

  /**
   * Set a prospect as activated.
   *
   * @param prospect
   */
  activateProspect(prospect: IProspect) {
    return this.http.post(`${environment.apiUrl}/e/edit/setstatus`, {id: prospect.aditemid, s: 'active'});
  }

  /**
   * Check if a prospect needs validation
   * Returns { mustValidate: boolean }
   */
  needsValidation(id: number) {
    return this.http.get(`${environment.apiUrl}/e/edit/needsmsvalidation?aditemid=${id}`);
  }

  /**
   *
   */
  sendSMSValidation(id: number, phoneNo: string) {
    return this.http.get(`${environment.apiUrl}/e/edit/sendsmsvalidation?aditemid=${id}&phoneNo=${phoneNo}`);
  }

  /**
   * Validates an sms-validator.
   * Returns one of:
   *  { err: null, validated: boolean }
   *  { err: 'timeout' }
   *  { err: 'maxretry' }
   */
  validateSMSValidation(id: number, validatorId: string, code: string) {
    return this.http.get(`${environment.apiUrl}/e/edit/validatesmsvalidation?aditemid=${id}&valid=${validatorId}&code=${code}`);
  }


  /**
   * Deactivate a prospect.
   *
   * @param prospect
   */
  deactivateProspect(prospect: IProspect) {
    return this.http.post(`${environment.apiUrl}/e/edit/setstatus`, {id: prospect.aditemid, s: 'deleted'});
  }

  /**
   * Delete a prospect (with soft-deletes).
   *
   * @param prospect
   */
  deleteProspect(prospect: IProspect) {
    return this.http.post(`${environment.apiUrl}/e/edit/setstatus`, {id: prospect.aditemid, s: 'really-deleted'});
  }

  /**
   * Mark a prospect as sold.
   *
   * @param prospect
   */
  markProspectSold(prospect: IProspect) {
    return this.http.post(`${environment.apiUrl}/e/edit/setstatus`, {id: prospect.aditemid, s: 'sold'});
  }

  /**
   * Check the XLBil status for a prospect.
   */
  getXlbilStatus(prospectId: string | number) {
    return this.http.get(`${environment.apiUrl}/x/xlbil/status?aditemid=${prospectId}`);
  }

  /**
   * Add a prospect to XLBil.
   */
  publishToXlBil(prospectId: string | number) {
    return this.http.post(`${environment.apiUrl}/x/xlbil/publish`, { aditemid: prospectId });
  }

  /**
   * Remove prospect from XLBil.
   */
  removeFromXlBil(prospectId: string | number) {
    return this.http.post(`${environment.apiUrl}/x/xlbil/cancel`, { aditemid: prospectId });
  }
}

export class UserProspects {
  active: IProspect[];
  inactive: IProspect[];
}

export class IProspect {
  aditemid: number;
  status: string;
  type: string;
  aditemData: IProspectAdData;
  mailout: any;
  time: {
    allModified: Date;
    created: Date;
    expires: Date;
    published: Date;
    userModified: Date;
  };
}

export class IProspectAdData {
  freetext: string;
  images: IImage[];
  price: number;
  urls: string[];
  contact: IContact;
  swap: { swappable: boolean, swapDesc: string };
  typedata: any;
}

interface IContact {
  addr: string;
  city: string;
  county: string;
  mobile: string;
  name: string;
  phoneNo: string;
  place: string;
  postNo: string;
}

export class IImage {
  id: number;
  desc: string;
}

export interface ImageList {
  images: IImage[];
}
