import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../global/services/user.service';
import { NotificationService } from '../../../global/services/notification.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      email: [this.activeRoute.snapshot.params.email, [Validators.required, Validators.email]],
    });
  }

  get f() {
    return this.resetForm.controls;
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.resetForm.invalid) {
      return;
    }

    this.loading = true;

    this.userService.resetPassword(this.resetForm.controls.email.value)
      .subscribe(
        data => {
          this.loading = false;
          this.router.navigate(['/bruker', 'nytt-passord']);
        },
        error => {
          this.loading = false;
          const errorMessage = 'Kunne ikke sende nytt passord. Er brukeren registrert?';
          this.notificationService.errorMessage(errorMessage, 5000);
        });
  }
}
