import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../global/services/notification.service';
import { ProspectService } from '../../services/prospect.service';
import { ProspectEditService } from '../../services/prospect-edit.service';
import { WindowRefService } from '../../../global/services/window-ref.service';

@Component({
  selector: 'app-prospect-publish',
  templateUrl: './prospect-publish.component.html',
  styleUrls: ['./prospect-publish.component.scss']
})
export class ProspectPublishComponent implements OnInit {
  adId: number;
  mode: string;
  prospectUnavailable: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private windowRef: WindowRefService,
    private notificationService: NotificationService,
    private prospectService: ProspectService,
    private prospectEditService: ProspectEditService,
    private prospectEdit: ProspectEditService,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params) => {
        this.adId = params.id;

        this.prospectService.getProspectStatus(this.adId).subscribe(
          (status: any) => {
            switch (status.status) {
              case 'new':
                this.mode = 'new';
                break;
              default:
                this.mode = 'new';
            }
          },
          (err) => {
            this.prospectUnavailable = true;
            this.notificationService.errorMessage('Failed to get prospect status.');
            console.error("Prospect status error", err);
          }
        );
      }
    );
  }

  /**
   * From backend {aditemid: 2312312, err: null }
   * Todo: Add error handling. Err='sms-validation-required' | 'notloggedin'
   * Todo: Replace english with norsk
   */
  publishAd() {
    this.prospectEditService.publishAd(this.adId).subscribe(
      (res: any) => {
        if (!res.err) {
          this.notificationService.successMessage('Ad published successfully.');
          this.router.navigate([`bruker/annonser/publisert/${this.adId}`]);
        } else {
          this.notificationService.errorMessage('Publisering feilet.');
        }
      },
      (err) => {
        this.notificationService.errorMessage('Publisering feilet.');
        console.error('Publish ad error', err);
      }
    );
  }

  editAd() {
    this.prospectEdit.getEditSession(this.adId).subscribe(
      (session) => { this.router.navigate(['/bruker', 'annonser', 'rediger', session.esid, 'edit']); }
    );
  }

  /**
   * Preview the ad.
   * This is not correct, should use preview with id from editsession
   */
  previewAd() {
    this.prospectEdit.getEditSession(this.adId).subscribe(
      (session) => { this.router.navigate(['/preview', session.esid]); }
    );

  }
}
