import { Component, OnInit, Input } from '@angular/core';
import { SavedSearch, SavedSearchesService } from '../../../global/services/saved-searches.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../../global/services/notification.service';
import { CurrSearchService } from '../../services/curr-search.service';
import { Observable } from 'rxjs';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SaveSearchModalComponent } from '../save-search-modal/save-search-modal.component';

@Component({
  selector: 'app-saved-searches',
  templateUrl: './saved-searches.component.html',
  styleUrls: ['./saved-searches.component.scss']
})
export class SavedSearchesComponent implements OnInit {
  @Input() hmaType: string = 'hmaAuto';

  dialogRef: MatDialogRef<SaveSearchModalComponent>;

  constructor(
    private savedSearchesService: SavedSearchesService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private currentSearch: CurrSearchService
  ) { }

  ngOnInit() {
  }

  saveCurrentSearch() {
    this.dialogRef = this.dialog.open(SaveSearchModalComponent, {
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(
      data => {
        if (!data) {
          return;
        }

        const query = this.currentSearch.get().args.toParams();

        const search = new SavedSearch;
        search.amsType = query['type'];
        search.name = data.name;
        search.interval = data.interval;
        search.query = query;
        search.status = data.notifications ? 'active' : 'disabled';

        this.savedSearchesService.saveSearch(search).subscribe(
          (result) => {
            this.notificationService.successMessage('Søket ble lagret.');
          },
          (error) => {
            console.log(error);
            this.notificationService.errorMessage('Kunne ikke lagre søk. Prøv på nytt.');
          }
        );
      }
    );
  }
}
