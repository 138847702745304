
import {Component, Input, OnInit, OnChanges} from '@angular/core';
import {Search} from '../../classes/search';
import {IsearchEntry, IsearchResult, SearchService} from '../../services/search.service';
import {Observable} from 'rxjs/internal/Observable';
import { SearchArgSingle, SearchArg } from '../../classes/search-args';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})


export class SearchResultsComponent implements OnInit, OnChanges {
  @Input() search: Search;
  @Input() searchRes$: Observable<IsearchEntry[]>;

  @Input() sort: SearchArgSingle;
  @Input() page: SearchArgSingle;

  resultEntries$: Observable<[IsearchEntry]>;

  currentPage;
  showCounter: boolean;



  constructor( private searchSvc: SearchService,
               private router: Router,
               private activatedRoute: ActivatedRoute) {

  }

  get searchParams() {
    return this.search.args.toParams();
  }

  ngOnInit() {
    this.currentPage = parseInt(this.page.val, 10) + 1;

    // Hide the counter when the search is empty
    this.showCounter = this.checkIfSearchEmpty();

  }

  checkIfSearchEmpty() {
    const nonSearchParams = ['sort', 'type', 'page'];

    return Object.keys(this.search.args.toParams()).filter((val) => nonSearchParams.indexOf(val) < 0).length > 0;
  }

  ngOnChanges(changes) {
    if (changes['searchRes$']) {
      if (this.checkIfSearchEmpty()) {
        this.showCounter = false;
      }

      this.searchRes$.subscribe(
        (res) => {
          this.showCounter = this.checkIfSearchEmpty();
        }
      );
    }
  }

  getPage(page: number) {
    this.currentPage = page;
    this.page.val = '' + (page - 1);
    this.page.onChange(this.page.val);
    window.scroll(0, 0);
  }
}

