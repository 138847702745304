
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {SearchArgMinMax} from '../../../classes/search-args';

@Component({
  selector: 'app-inp-min-max',
  templateUrl: './inp-min-max.component.html',
  styleUrls: ['./inp-min-max.component.css']
})


export class InpMinMaxComponent implements OnInit {

  @Input() arg: SearchArgMinMax;
  @Input() label: string;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() minPrefix: string;
  @Input() maxPrefix: string;
  @Input() suffix: string;

  model: number[];
  useInputs = false;

  @ViewChild('slider') slider: any;

  sliderConfig: any = {
    behaviour: 'drag',
    connect: true,
    start: [0, 100000],
    keyboard: true,
    step: 10000,
    range: {
      min: 0,
      max: 0
    }
  };

  constructor() {
    this.model = [0, 0];
  }

  ngOnInit() {
    this.model = [this.sliderConfig.range.min, this.sliderConfig.range.max];
    this.sliderConfig.start[0] = this.arg.min;
    this.sliderConfig.start[1] = this.arg.max;
    this.sliderConfig.range.min = this.min;
    this.sliderConfig.range.max = this.max;
    this.sliderConfig.step = this.step;

    if (this.arg.min != null) {
      this.model[0] = this.arg.min;
    }

    this.model[1] = this.arg.max != null ? this.arg.max : this.max;
  }

  // Updates slider and search results
  onInputChange() {
    this.model = [this.arg.min, this.arg.max];
    this.arg.onChange({min: this.arg.min, max: this.arg.max});
  }

  // Updates input values and search results
  onSliderChange() {
    if (this.model[0] <= this.min) {
      this.model[0] = 0;
    }

    if (this.model[1] >= this.max) {
      this.model[1] = 0;
    }

    this.arg.min = this.model[0];
    this.arg.max = this.model[1];
    this.arg.onChange({min: this.arg.min, max: this.arg.max});
  }

  minText() {
    let labelText = '';

    if ((!this.model[0] || this.model[0] <= this.min) && this.minPrefix) {
      labelText += this.minPrefix;
    }

    if (this.model[0] === 0) {
      labelText += this.min;
    } else {
      labelText += this.model[0];
    }

    return labelText;
  }

  maxText() {
    let labelText = '';

    if ((!this.model[1] || this.model[1] >= this.max) && this.maxPrefix) {
      labelText += this.maxPrefix;
    }

    if (this.model[1] === 0) {
      labelText += this.max;
    } else {
      labelText += this.model[1];
    }

    return labelText;
  }

  changeInputType() {
    this.useInputs = !this.useInputs;

    if (this.useInputs && this.model[1] === this.max) {
      this.model[1] = null;
    } else if (!this.useInputs && (this.model[1] == null || this.model[1] === 0)) {
      this.model[1] = this.max;
    }
  }
}

