import { Component, OnInit, OnDestroy, Input, ElementRef, HostBinding, Optional, Self } from '@angular/core';
import { FormControl, FormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, FormBuilder } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'app-input-yes-no',
  templateUrl: './input-yes-no.component.html',
  styleUrls: ['./input-yes-no.component.scss'],
  providers: [
    {provide: MatFormFieldControl, useExisting: InputYesNoComponent}]
})
export class InputYesNoComponent implements OnInit, OnDestroy, MatFormFieldControl<string> {
  @Input() inputName: string;

  static nextId = 0;

  errorState = false;
  stateChanges = new Subject<void>();
  controlType = 'app-input-yesno';

  get value(): string | null {
    return this.form.get(this.inputName).value;
  }

  set value(select: string | null) {
    this.stateChanges.next();
  }

  @Input() form: FormGroup;

  @HostBinding() id = `yesno-input-${InputYesNoComponent.nextId++}`;

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  private _placeholder: string;
  focused = false;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    return this.value === null || this.value === '' || this.value.length === 0;
  }

  get hasError() {
    return this.required && this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }
  private _disabled = false;

  @HostBinding('attr.aria-describedby') describedBy = '';

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    fb: FormBuilder,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>
  ) {
    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  onContainerClick(event: MouseEvent) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }
}
