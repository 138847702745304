import { Component, OnInit } from '@angular/core';
import { ArticleService, Article } from 'src/app/articles/services/article.service';

@Component({
  selector: 'app-list-article',
  templateUrl: './list-article.component.html',
  styleUrls: ['./list-article.component.scss']
})
export class ListArticleComponent implements OnInit {
  articles: Article[] = [];

  constructor(private articleService: ArticleService) {}

  ngOnInit(): void {
    console.log('hei');
    this.articles = this.articleService.getArticles();
  }
}
