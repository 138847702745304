import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '../../../global/services/user.service';
import { NotificationService } from '../../../global/services/notification.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {

  resetForm: FormGroup;
  submitted = false;
  loading = false;

  uuid: string;
  token: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.activeRoute.params.subscribe(
      params => {
        this.uuid = params.uuid;
        this.token = params.token;
      }
    );
  }

  get f() {
    return this.resetForm.controls;
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.resetForm.invalid) {
      return;
    }

    this.loading = true;

    this.userService.setPassword(this.uuid, this.token, this.resetForm.value.password)
      .pipe(first())
      .subscribe(
        data => {
          this.notificationService.successMessage('Ditt passord har blitt endret.');
          this.router.navigate(['/bruker']);
        },
        error => {
          this.loading = false;

          let errorMessage;

          switch (error.status) {
            case 410:
              errorMessage = 'Server timeout. Vennligst prøv igjen.';
              break;
            case 401:
              errorMessage = 'Feil eller brukt token. Vennligst gå tilbake og prøv på nytt for å sette nytt passord.';
              break;
            case 404:
              errorMessage = 'Fant ikke bruker. Vennligst prøv på nytt.';
              break;
            case 403:
              errorMessage = 'Denne brukeren er blokkert.';
              break;
            default:
              errorMessage = 'Noe gikk galt med forespørselen. Vennligst prøv igjen.'
          }

          this.notificationService.errorMessage(errorMessage);
        });
  }
}
