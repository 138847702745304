import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../global/services/notification.service';
import { ProspectService } from '../../services/prospect.service';

@Component({
  selector: 'app-prospect-sms-validate',
  templateUrl: './prospect-sms-validate.component.html',
  styleUrls: ['./prospect-sms-validate.component.scss']
})
export class ProspectSmsValidateComponent implements OnInit {
  adId: number;
  needValidation: boolean;
  validatorId: string | undefined;
  smsForm: FormGroup;
  codeForm: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private prospectService: ProspectService,
    private fb: FormBuilder
  ) {
    this.smsForm = this.fb.group({
      phone: ['', [Validators.required, Validators.pattern('[0-9]{8}')]]
    });
    this.codeForm = this.fb.group({
      code: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params) => {
        this.adId = +params['id'];

        this.prospectService.needsValidation(this.adId).subscribe(
          (res: any) => {
            this.needValidation = !!res.mustValidate;

            if (!this.needValidation) {
              console.log("Annonse trenger ikke vertifisering.");
              this.router.navigate([`bruker/annonser/rediger/${this.adId}/valg`]);
            }
          },
          (err) => {
            console.error('Failed to check if validation is needed', err);
          }
        );
      }
    );
  }

  sendSMS() {
    if (this.smsForm.invalid) {
      this.notificationService.errorMessage('Vennligst oppgi et gyldig telefonnummer.');
      return;
    }

    const phone = this.smsForm.get('phone').value;

    this.prospectService.sendSMSValidation(this.adId, phone).subscribe(
      (res: any) => {
        this.validatorId = res.validator;
        this.notificationService.successMessage('SMS sendt');
        console.log("validator-id", res.validator);
      },
      (err) => {
        this.validatorId = undefined;
        console.error("Failed to send SMS validation", err);
        this.notificationService.errorMessage('Klarte ikke sende SMS. Vennligst prøv på nytt.');
      }
    );
  }

  validateSMS() {
    if (this.codeForm.invalid || !this.validatorId) {
      this.notificationService.errorMessage('Vennligst fyll inn en gyldig kode');
      return;
    }

    const code = this.codeForm.get('code').value;
    this.prospectService.validateSMSValidation(this.adId, this.validatorId, code).subscribe(
      (res: any) => {
        switch (res.err) {
          case null:
            if (res.validated) {
              this.notificationService.successMessage('SMS-Verfisering vellykket.');
              this.router.navigate([`bruker/annonser/rediger/${this.adId}/valg`]);
            } else {
              this.notificationService.errorMessage('SMS-validering feilet. Vennligst prøv på nytt.');
            }
            break;
          case 'timeout':
            this.notificationService.errorMessage('Du har brukt for lang tid. Vennligst be om en ny kode.');
            break;
          case 'maxretry':
            this.notificationService.errorMessage('Du har forsøkt for mange ganger. Vennligst start på nytt.');
            break;
        }
      },
      (err) => {
        console.error("SMS validation error", err);
        this.notificationService.errorMessage('Klarte ikke å validere SMS.');
      }
    );
  }
}
