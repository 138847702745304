import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../global/services/authentication.service';

@Component({
  selector: 'app-save-search-modal',
  templateUrl: './save-search-modal.component.html',
  styleUrls: ['./save-search-modal.component.scss']
})
export class SaveSearchModalComponent implements OnInit {
  saveSearchForm: FormGroup;
  loggedIn: boolean;
  intervalOptions = [
    { id: 'hourly', name: 'Hver time' },
    { id: 'daily', name: 'Daglig' },
    { id: 'everyotherday', name: 'Annenhver dag' },
    { id: 'weekly', name: 'Ukentlig' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    public dialogRef: MatDialogRef<SaveSearchModalComponent>
  ) { }

  ngOnInit() {
    this.saveSearchForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      notifications: [true],
      interval: ['hourly'],
    });

    this.authenticationService.isLoggedIn().subscribe(
      (result) => { this.loggedIn = result; }
    );
  }

  save() {
    if (!this.saveSearchForm.valid) {
      return;
    }

    this.dialogRef.close(this.saveSearchForm.value);
  }

  gotoLogin() {
    this.router.navigate(['/bruker/logg-inn']);
    this.dialogRef.close();
  }
}
