import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionHistoryService {
  httpLog: any[] = [];
  routerLog: string[] = [];

  constructor(private router: Router) {

  }

  /**
   * Initialize service: sets up tracking of browse history.
   */
  init() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.logToRouterLog(event.url);
      }
    });
  }

  logToHttpLog(logItem: any) {
    this.httpLog.unshift(logItem);
    this.httpLog = this.httpLog.slice(0, 15);
  }

  getHttpLog(): any[] {
    return this.httpLog;
  }

  logToRouterLog(logItem: string) {
    this.routerLog.unshift(logItem);
    this.routerLog = this.routerLog.slice(0, 15);
  }

  getRouterLog(): string[] {
    return this.routerLog;
  }
}
