import { Component, Input, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-prospect-map',
  templateUrl: './prospect-map.component.html',
  styleUrls: ['./prospect-map.component.scss']
})
export class ProspectMapComponent {

  @Input() address: string;
  googleMapsUrl: SafeResourceUrl;
  @Input() mapType: string = 'roadmap'; 
  @Input() language: string = 'no'; 
  @Input() region: string = 'NO';
  @Input() zoom: number = 10; 
  @Input() apiKey: string = 'AIzaSyAddnqB1abi5svN39b78pxsvT-ZxfQIGq4&'; 


  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.address && this.address) {
      this.setGoogleMapsUrl();
    }
  }

  setGoogleMapsUrl(): void {
    if (this.address) {
      const baseUrl = 'https://www.google.com/maps/embed/v1/place';
      const queryParams = `?q=${encodeURIComponent(this.address)}&key=${this.apiKey}zoom=${this.zoom}&maptype=${this.mapType}&language=${this.language}&region=${this.region}`;
      const fullUrl = `${baseUrl}${queryParams}`;
      this.googleMapsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fullUrl);
    } else {
      console.error('Address is undefined');
    }
  }

}
