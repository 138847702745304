import { Component, OnInit } from '@angular/core';
import { ProspectService, IProspect } from '../../services/prospect.service';
import { NotificationService } from '../../../global/services/notification.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import * as Highcharts from 'highcharts';
import {WindowSizeService} from '../../../global/services/window-size.service';

@Component({
  selector: 'app-prospect-stats',
  templateUrl: './prospect-stats.component.html',
  styleUrls: ['./prospect-stats.component.scss']
})
export class ProspectStatsComponent implements OnInit {
  Highcharts = Highcharts;
  prospect$: Observable<IProspect>;
  stats$: any;
  numDays: number;

  highchartsOptions = {
    chart: {
      type: 'column',
      marginTop: 30,
      backgroundColor: 'rgba(255, 255, 255, 0.0)',
      colors: ['#2f7ed8', '#8bbc21', '#0d233a']
    },

    credits: false,

    title: {
      text: ''
    },

    xAxis: {
      startOnTick: true,
      endOnTick: true,
      showFirstLabel: true,
      showLastLabel: true,
      type: 'datetime',
      tickInterval: 7 * 24 * 3600 * 1000
    },

    yAxis: {
      title: {
        text: undefined
      },
      allowDecimals: false
    },

    plotOptions: {
      column: {
        pointPadding: 0.0,
        borderWidth: 0
      }
    },

    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom'
    },
  };

  viewStats = {
    series: [],
  };

  viewStatsUpdate = true;

  searchStats = {
    series: [],
  };

  searchStatsUpdate = true;

  constructor(
    private prospectsService: ProspectService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private windowSizeService: WindowSizeService
  ) { }

  ngOnInit() {
    Highcharts.setOptions(this.highchartsOptions);

    //this.numDays = this.windowSizeService.getAgentDevice() === 'desktop' ? 90 : 14;
    this.numDays = 90;

    this.activatedRoute.params.subscribe(
      (params) => {
        this.prospect$ = this.prospectsService.getProspectDetails(params.id);
        this.stats$ = this.prospectsService.getProspectStats(params.id, this.numDays );

        this.prospect$.subscribe();
        this.stats$.subscribe(
          (stats) => {
            const viewSeries = [
              {name: 'Ordinær', resource: 'resViewNonPri'},
              // {name: 'Prioritert', resource: 'resViewPri'},
              {name: 'Fra Broom.no', resource: 'resViewBroom'}
            ];

            this.addSeries(viewSeries, stats, this.viewStats);

            const searchSeries = [
              {name: 'Ordinær', resource: 'resSearchNonPri'},
              {name: 'Prioritert', resource: 'resSearchPri'},
            ];

            this.addSeries(searchSeries, stats, this.searchStats);
          }
        );
      }
    );
  }

  addSeries(seriesToAdd, stats, seriesConfig) {
    const seriesData = [];

    for (const series of seriesToAdd) {
      const data = [];

      for (let i = 0; i < stats[series.resource].length; i++) {
        data.push([
          Date.parse(stats[series.resource][i].dt),
          stats[series.resource][i].cnt
        ]);
      }

      seriesData.push({
        name: series.name,
        data: data
      });
    }

    seriesConfig.series = seriesData;
  }
}
