import { Component, OnInit, OnDestroy, Input, ElementRef, HostBinding, Optional, Self } from '@angular/core';
import { FormControl, FormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, FormBuilder } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  providers: [
    {provide: MatFormFieldControl, useExisting: InputDateComponent}]
})
export class InputDateComponent implements OnInit, OnDestroy, MatFormFieldControl<number>, ControlValueAccessor {
  @Input() inputName: string;
  @Input() form: FormGroup;
  dateForm: FormGroup;
  static nextId = 0;

  dateValue: Date;

  errorState = false;
  stateChanges = new Subject<void>();
  controlType = 'app-input-date';

  onChange = (timestamp: number) => {};
  onTouched = () => {};

  get value(): number {
    const formValue = this.dateForm.get('date').value;
    if (formValue === null || formValue === 0 || formValue === '') {
      return null;
    }

    return new Date(formValue).getTime();
  }

  set value(date: number) {
    this.stateChanges.next();
    this.onChange(date);
  }

  @HostBinding() id = `date-input-${InputDateComponent.nextId++}`;

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  private _placeholder: string;
  focused = false;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    return !this.value || this.value === 0;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }
  private _disabled = false;

  @HostBinding('attr.aria-describedby') describedBy = '';

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  writeValue(date: number) {
    if (date !== null && date > 0) {
      this.dateForm.get('date').setValue(this.timestampToDateString(date), {emitEvent: false});
    }
    this.onChange(this.value);
  }

  timestampToDateString(date: number | string) {
    const dateVal = new Date(date);
    return dateVal.getFullYear() + '-'
      + (dateVal.getMonth() + 1).toString().padStart(2, '0') + '-'
      + dateVal.getDate().toString().padStart(2, '0');
  }

  registerOnChange(fn: (date: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onContainerClick(event: MouseEvent) {
  }

  ngOnInit() {
    if (this.form.get(this.inputName).value) {
      this.dateForm.get('date').setValue(this.timestampToDateString(this.form.get(this.inputName).value));
    }

    this.dateForm.get('date').valueChanges.subscribe(
      (value) => {
        if (value) {
          this.form.get(this.inputName).setValue(value);
        }
      }
    );
  }

  ngOnDestroy() {
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }


  constructor(
    @Optional() @Self() public ngControl: NgControl,
    fb: FormBuilder,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.dateForm = fb.group({
      date: []
    });
  }
}
