import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

export interface Article {
  slug: string;
  title: string;
  content?: string;
  contentUrl?: string;
  imageUrl: string;
  imageCaption?: string; 
  summary: string ;
}

@Injectable({
  providedIn: 'root',
})
export class ArticleService {



  private Articles: Article[] = [
    {
      slug: 'selg-bilen-din-gratis-i-norge-en-guide-til-effektiv-bilannonsering',
      title: 'Selg bilen din gratis i Norge: En guide til effektiv bilannonsering',
      contentUrl: '/assets/articles/selg-bilen.html',
      imageUrl: '/assets/img/articles/AutoDB-resized.webp',
      imageCaption: 'På AutoDB kan du annonsere dine kjøretøy helt kostnadsfritt',
      summary: 'Annonser ditt kjøretøy gratis på en effektiv måte',
    },
    {
      slug: 'salg-av-bruktbil-slik-får-du-best-mulig-pris-for-bilen-din',
      title: 'Salg av bruktbil: Slik får du best mulig pris for bilen din',
      contentUrl: '/assets/articles/salg-av-bruktbil.html',
      imageUrl: '/assets/img/articles/salg-av-bruktbil-resized1.webp',
      imageCaption: 'Det er mange grep du kan ta for å oppnå best mulig pris når du skal selge bilen din',
      summary: 'Vi forteller deg hvordan du kan oppnå høyest mulig pris på din bil ved salg',
    },
    {
      slug: 'de-10-mest-populare-bruktbilene-i-2024',
      title: 'De 10 mest populære bruktbilene i 2024',
      contentUrl: '/assets/articles/populare-bruktbiler.html',
      imageUrl: '/assets/img/articles/populare-bruktbiler-resized.webp',
      summary: 'En oversikt over de mest populære bruktbilene i 2024',
    },
    {
      slug: 'bilforsikring-i-norge-finn-den-beste-dekningen-unngå-vanlige-feil-og-velg-riktig-forsikringsselskap',
      title: 'Bilforsikring i Norge: Finn den beste dekningen, unngå vanlige feil og velg riktig forsikringsselskap.',
      contentUrl: '/assets/articles/bilforsikring.html',
      imageUrl: '/assets/img/articles/bilforsikring-resized1.webp',
      imageCaption: 'Når uhellet først er ute er det godt å ha et forsikringsselskap i ryggen',
      summary: 'En innføring i ulike typer bilforsikring og ulike selskap',
    },
    {
      slug: 'vedlikehold-av-bobil-og-campingvogn-en-omfattende-guide-for-bobileier',
      title: 'Vedlikehold av bobil og campingvogn: En omfattende guide for bobileiere',
      contentUrl: '/assets/articles/bobil.html',
      imageUrl: '/assets/img/articles/bobil-resized1.webp',
      summary: 'Lure råd for vedlikehold for bobil og campingvogn',
    },
    {
      slug: 'lease-eller-kjope-bil-hva-bør-du-velge',
      title: 'Lease eller kjøpe bil: hva bør du velge?',
      contentUrl: '/assets/articles/lease.html',
      imageUrl: '/assets/img/articles/lease-resized.webp',
      summary: 'Ting å tenke på om du vurderer å lease eller kjøpe bil',
    },
    {
      slug: 'elektrisk-eller-fossil-bil-hva-bor-du-velge',
      title: 'Elektrisk eller fossil bil - hva bør du velge?',
      contentUrl: '/assets/articles/elektrisk.html',
      imageUrl: '/assets/img/articles/elektrisk-resized.webp',
      summary: 'Ting å vurdere når du skal velge mellom elektrisk og fossil',
    },
    {
      slug: 'isabell-rustad-ung-og-lovende-racerkjorer-fra-motorsport-familie',
      title: 'Isabell Rustad: Ung og lovende racerkjører fra motorsport familie',
      contentUrl: '/assets/articles/isabell.html',
      imageUrl: '/assets/img/articles/isabell-resized.webp',
      summary: 'Intervju med Isabell Rustad som er sponset av AutoDB',
    },
    {
      slug: 'vedlikehold-av-bilen-10-gode-rad-for-en-bedre-bruktpris',
      title: 'Vedlikehold av bilen: 10 gode råd for en bedre bruktpris',
      contentUrl: '/assets/articles/vedlikehold.html',
      imageUrl: '/assets/img/articles/vedlikehold-resized.webp',
      imageCaption: 'Regelmessig oljeskift er essensielt for å opprettholde bilens ytelse og levetid. Sørg for at bilen alltid har riktig oljenivå',
      summary: 'Gode råd for optimalt vedlikehold av din bil',
    },
    {
      slug: 'garantiforsikring-for-biler-en-smart-investering',
      title: 'Garantiforsikring for biler: En smart investering?',
      contentUrl: '/assets/articles/garantiforsikring.html',
      imageUrl: '/assets/img/articles/garantiforsikring-resized.webp',
      summary: 'En enkel innføring om garantiartikler',
    },
    {
      slug: 'slik-gjor-du-bilen-klar-for-salg-en-trinn-for-trinn-guide',
      title: 'Slik gjør du bilen klar for salg: En trinn for trinn guide',
      contentUrl: '/assets/articles/salg.html',
      imageUrl: '/assets/img/articles/salg-resized.webp',
      summary: 'En omfattende guide til å gjøre bilen klar for salg',
    },
    {
      slug: 'salgskontrakt-for-bil-dette-bor-den-inneholde',
      title: 'Salgskontrakt for bil: Dette bør den inneholde',
      contentUrl: '/assets/articles/salgskontrakt.html',
      imageUrl: '/assets/img/articles/salgskontrakt-resized.webp',
      imageCaption: 'En grundig kjøpekontrakt ved overlevering av bilnøkkelen sikrer en trygg og konfliktfri handel.',
      summary: 'En oversikt over hva salgskontrakten bør innholde ved kjøp og salg av kjøretøy',
    },
  ];

  constructor(private http: HttpClient) {}

  getArticles(): Article[] {
    return this.Articles;
  }

  getArticleBySlug(slug: string): Article | undefined {
    return this.Articles.find(article => article.slug === slug);
  }

  getArticleContent(article: Article): Observable<string> {
    if (article.content) {
      return of(article.content);
    } else if (article.contentUrl) {
      return this.http.get(article.contentUrl, { responseType: 'text' });
    } else {
      return of('');
    }
  }
}
