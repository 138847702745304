import { Component, OnInit } from '@angular/core';
import { PaymentService, Receipt } from '../../services/payment.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IProspect, ProspectService } from '../../services/prospect.service';


@Component({
  selector: 'app-view-receipt',
  templateUrl: './view-receipt.component.html',
  styleUrls: ['./view-receipt.component.scss']
})
export class ViewReceiptComponent implements OnInit {

  receipt$: Observable<Receipt>;
  prospect: Observable<IProspect>

  constructor(
    private paymentService: PaymentService,
    private activeRoute: ActivatedRoute,
    private prospectService: ProspectService
  ) { }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      this.receipt$ = this.paymentService.getReceipt(params.id);
    });
  }

  printReceipt() {
    window.print();
  }

}
