
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes} from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FrontpageComponent } from '../comp/frontpage/frontpage.component';
import { SearchViewComponent } from '../comp/search-view/search-view.component';
import { ViewProspectComponent } from '../comp/view-prospect/view-prospect.component';
import { ImageSliderComponent } from '../../global/comp/image-slider/image-slider.component';
import { ResultEntryComponent } from '../comp/result-entry/result-entry.component';
import { SearchResultsComponent} from '../comp/search-results/search-results.component';
import { ResultEntrySmallComponent } from '../comp/result-entry-small/result-entry-small.component';
import { ContactFormComponent } from '../comp/contact-form/contact-form.component';
import { ContactFormComponentTest } from '../comp/contact-form-test/contact-form-test.component';
import { SearchRootComponent} from '../comp/search-root/search-root.component';
import { SearchFiltersComponent } from '../comp/search-filters/search-filters.component';
import { SharedModule } from '../../shared/shared.module';
import { InpMinMaxComponent } from '../comp/input/inp-min-max/inp-min-max.component';
import { InpSingleComponent } from '../comp/input/inp-single/inp-single.component';
import { InpSingleSelectComponent } from '../comp/input/inp-button-list/inp-button-list.component';
import { InpMultiValComponent } from '../comp/input/inp-multi-val/inp-multi-val.component';
import { SortSelectComponent } from '../comp/input/sort-select/sort-select.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProspectSliderComponent } from '../../global/comp/prospect-slider/prospect-slider.component';
import { ProspectMapComponent } from '../comp/prospect-map/prospect-map.component';
import { ProspectBadgesComponent } from '../comp/prospect-badges/prospect-badges.component';
import { FullscreenSliderComponent } from '../../global/comp/fullscreen-slider/fullscreen-slider.component';
import { NouisliderModule } from 'ng2-nouislider';
import { BrowserModule } from '@angular/platform-browser';
import { KeyInfoComponent } from 'src/app/search/comp/view-prospect/key-info/key-info.component';
import { KeyInfoAutoComponent } from 'src/app/search/comp/view-prospect/key-info/key-info-auto/key-info-auto.component';
import { KeyInfoMcComponent } from 'src/app/search/comp/view-prospect/key-info/key-info-mc/key-info-mc.component';
import { KeyInfoMobileHomeComponent } from 'src/app/search/comp/view-prospect/key-info/key-info-mobile-home/key-info-mobile-home.component';
import { KeyInfoCampingComponent } from 'src/app/search/comp/view-prospect/key-info/key-info-camping/key-info-camping.component';
import { KeyInfoBoatComponent } from 'src/app/search/comp/view-prospect/key-info/key-info-boat/key-info-boat.component';
import { SavedSearchesComponent } from '../comp/saved-searches/saved-searches.component';
import { SaveSearchModalComponent } from '../comp/save-search-modal/save-search-modal.component';

const routes: Routes = [
  {
    path: '',
    component: SearchRootComponent,
    children: [
      { path: '', component: FrontpageComponent },
      { path: 'search', component: SearchViewComponent },
      { path: 'seller-search', component: SearchViewComponent },
      { path: 'view/:id', component: ViewProspectComponent },
      { path: 'view/:id/highlightshare', component: ViewProspectComponent }, // pseudo page for highlighting share buttons from billink
      { path: 'view/:id/contact', component: ContactFormComponent },
      { path: 'view/:id/contact-test', component: ContactFormComponentTest },
      { path: 'preview/:sessionId', component: ViewProspectComponent, data: { preview: true } },
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild( routes ),
    BrowserModule,
    NouisliderModule,
    NgxPaginationModule,
    NgxSmartModalModule.forRoot(),
    ClipboardModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    SearchViewComponent,
    SearchFiltersComponent,
    ViewProspectComponent,
    ResultEntryComponent,
    SearchResultsComponent,
    SearchRootComponent,
    ResultEntryComponent,
    InpMinMaxComponent,
    InpSingleComponent,
    InpSingleSelectComponent,
    InpMultiValComponent,
    SortSelectComponent,
    ProspectSliderComponent,
    ImageSliderComponent,
    ProspectMapComponent,
    ContactFormComponent,
    ContactFormComponentTest,
    SavedSearchesComponent,
    SaveSearchModalComponent,
    FrontpageComponent,
    ResultEntrySmallComponent,
    ProspectBadgesComponent,
    FullscreenSliderComponent,
    KeyInfoComponent,
    KeyInfoAutoComponent,
    KeyInfoMcComponent,
    KeyInfoMobileHomeComponent,
    KeyInfoCampingComponent,
    KeyInfoBoatComponent,
  ]
})

export class SearchModule {}
