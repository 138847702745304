import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService, User } from '../../../global/services/user.service';
import { NotificationService } from '../../../global/services/notification.service';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {
  accountForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.userService.getCurrentUser().subscribe(
      (userData) => {
        this.accountForm = this.formBuilder.group({
          name: [userData.name],
          addr: [userData.addr],
          postCode: [userData.postCode, Validators.pattern(/[0-9]{4}/)],
          city: new FormControl({ value: ' ', disabled: true }),
          phone: [userData.mobile.length > 0 ? userData.mobile[0].no : '', [Validators.pattern('[0-9]{8}')]]
        });

        this.onChangePostCode();
      }
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.accountForm.controls;
  }

  onSubmit() {
    this.userService.updateUserData(this.accountForm.value).subscribe(
      (user) => {
        this.notificationService.successMessage('Brukerdata har blitt oppdatert.');
      },

      (error) => {
        this.notificationService.errorMessage('Kunne ikke oppdatere brukerdata. Prøv igjen senere.');
      }
    );
  }

  onChangePostCode() {
    if (this.accountForm.controls.postCode.valid) {
      this.userService.getCityFromPostCode(this.accountForm.controls.postCode.value).subscribe(
        (result) => {
          this.accountForm.controls.city.setValue(result.result);
        }
      )
    }
  }
}

